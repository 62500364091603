import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllAdsInfo } from "../../redux/advertInfo";
import LiveAdvertPagination from '../../extraFunctions/LiveAdvertPagination';

const LiveCampaigns = () => {
    const dispatch = useDispatch();
    const { adverts } = useSelector((state) => state.advertInfo);
    const [currentPage, setCurrentPage] = useState(1);
    const [liveCurrentPage, setLiveCurrentPage] = useState(1);
    const [itemsPerPage] = useState(3); // Set the number of items per page
    const [liveItemsPerPage] = useState(3); // Set the number of items per page
    const indexOfLastAdItem = currentPage * itemsPerPage;
    const indexOfFirstAdItem = indexOfLastAdItem - itemsPerPage;

    const Paginate = (pageNumber) => setLiveCurrentPage(pageNumber);


    useEffect(() => {
        dispatch(getAllAdsInfo());
    }, [dispatch]);

    // Utility function to check if an advert is expired
    const isExpired = (toDate) => {
        const currentDate = new Date();
        const expiryDate = new Date(toDate);
        return currentDate > expiryDate;
    };

    // Filter out expired adverts
    const validAdverts = adverts?.filter(advert => !isExpired(advert?.to_duration));
    const currentAdItems = validAdverts?.slice(indexOfFirstAdItem, indexOfLastAdItem);


    return (
        <div>
            <div className='livecampaign__container'>
                <p className="advert__text livecampaign__text">Live Campaigns</p>
                <div className="ads__details">
                    {currentAdItems?.map((advert) => (
                        <div className="ads__content" key={advert?.id}>
                            <img src={advert?.img_url} className="advert__img" alt="advert__img" />
                            <p className="advert__img__text">{advert?.message}</p>
                        </div>
                    ))}
                </div>
            </div>

            <LiveAdvertPagination
                itemsPerPage={liveItemsPerPage}
                totalItems={validAdverts?.length}
                paginate={Paginate}
                className="table_pagination"
            />
        </div>
    );
}

export default LiveCampaigns;
