import React, { useState, useEffect } from 'react';
import {
    Text,
    VStack,
    Input,
    Stack,
    HStack,
    InputGroup,
    Button,
    Spacer,
    Flex,
    CardBody,
    Card,
    useToast,
    PinInput, PinInputField
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { resetPassword } from '../../Services/Api';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";


const Otp = () => {
    const toast = useToast();
    const [otp, setOtp] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isloading, setIsLoading] = useState(false);

    const navigate = useNavigate();


    function showToast(title, message, status) {
        toast({
            title: message,
            status: status,
            duration: 5000,
            isClosable: true,
            position: 'top',
        });
    }


    const data = {
        token: otp,
        password: password,
        email: email
    };

    const dataInfo = {
        email: email
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'email') {
            setEmail(value);
        } else if (name === 'password') {
            setPassword(value);
        } else if (name && name.startsWith('pin')) {
            // Handle individual pin input fields
            const pinIndex = parseInt(name.replace('pin', ''), 10);
            setOtp(prevOtp => {
                const newOtp = [...prevOtp];
                newOtp[pinIndex - 1] = value;
                return newOtp.join('');
            });
        }
    };

    const handleSubmit = async (e) => {
        setIsLoading(false);
        e.preventDefault();
        try {
            // Call the resetPassword endpoint
            const res = await resetPassword(data);

            if (res?.data?.status_code === 200) {
                showToast("Success", "Password Reset Successful", "success");
                navigate('/');
            } else if (res?.data?.status_code === 400) {
                setIsLoading(false);
                showToast("Error", "Password Reset Failed", "error");
            }
        } catch (err) {
            console.error("Error in the first API call:", err);
        }
    };

    return (
        <div className="forgot__password__container">
            <VStack spacing={10}>
                <p className="forgot__password"> Reset Password</p>
                <form>
                    <Card size="lg" width="40vw" id="forgot__card">
                        <CardBody>
                            <Stack spacing={5}>
                                <Spacer />
                                <HStack
                                    style={{
                                        display: "flex", alignContent: "center",
                                        justifyContent: "space-evenly", alignItems: "center",
                                        justifyItems: "center", marginBottom: "20px"
                                    }}>
                                    <PinInput otp size='lg'>
                                        <PinInputField name="pin1" onChange={handleChange} />
                                        <PinInputField name="pin2" onChange={handleChange} />
                                        <PinInputField name="pin3" onChange={handleChange} />
                                        <PinInputField name="pin4" onChange={handleChange} />
                                        <PinInputField name="pin5" onChange={handleChange} />
                                    </PinInput>
                                </HStack>
                                <Input
                                    value={email}
                                    onChange={handleChange}
                                    size="lg"
                                    placeholder="Email"
                                    name="email"
                                    id="reset__email"
                                />
                                <Input
                                    value={password}
                                    onChange={handleChange}
                                    size="lg"
                                    placeholder="Enter your new password"
                                    name="password"
                                    id="reset__input"
                                />

                                <Spacer />
                                <Button
                                    isLoading={isloading}
                                    loadingText="Verifying your credentials ..."
                                    onClick={handleSubmit}
                                    colorScheme="blue"
                                    variant="outline"
                                    rightIcon={<ArrowForwardIcon />}
                                    className="login__button"
                                    id="reset__button"
                                >
                                    Reset Password
                                </Button>
                                <Flex>
                                    <Spacer />
                                </Flex>
                            </Stack>
                        </CardBody>
                    </Card>
                </form>
            </VStack>
        </div>
    );
}

export default Otp;

