import axios from "axios";

const TOKEN = localStorage.getItem("AccessToken");

export const signup = async (data) => {
  const res = await axios.post(
    "https://api2.platterwise.com/admin-api/auth/register",
    data
  );
  return res;
};

export const login = async (data) => {
  const res = await axios.post(
    "https://api2.platterwise.com/admin-api/auth/login",
    data
  );
  return res;
};

export const getDashboardData = async () => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };

    const res = await axios.get(
      "https://api2.platterwise.com/admin-api/dashboard/index",
      { headers }
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getRestaurantData = async () => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };

    const res = await axios.get(
      "https://api2.platterwise.com/admin-api/restaurant/all-restaurants",
      { headers }
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getRestaurantAnalysis = async () => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };

    const res = await axios.get(
      " https://api2.platterwise.com/admin-api/restaurant/index",
      { headers }
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getUsersData = async () => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };

    const res = await axios.get(
      " https://api2.platterwise.com/admin-api/user-list/index",
      { headers }
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAllUsers = async () => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };

    const res = await axios.get(
      "https://api2.platterwise.com/admin-api/user-list/all",
      { headers }
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getTopPerforming = async () => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };

    const res = await axios.get(
      "https://api2.platterwise.com/admin-api/restaurant/top-performing",
      { headers }
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getSalesRevenue = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };

    const res = await axios.post(
      "https://api2.platterwise.com/admin-api/restaurant/all-sales-restaurant",
      data,
      { headers }
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getMonthlyReservation = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };

    const res = await axios.post(
      "https://api2.platterwise.com/admin-api/dashboard/reservtaion-reports",
      data,
      { headers }
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getUserViewList = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };

    const res = await axios.post(
      "https://api2.platterwise.com/admin-api/user-list/view-user",
      data,
      { headers }
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// Assuming TOKEN is stored in localStorage or passed as a parameter
export const getPosts = async (userId) => {
  try {
    const TOKEN = localStorage.getItem("AccessToken"); // Get the token from localStorage
    if (!TOKEN) {
      throw new Error("No access token found."); // Throw an error if no token is found
    }

    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };

    // Use the userId in the API endpoint
    const url = `https://api2.platterwise.com/admin-api/user-list/user-post-activities/${userId}`;
    const response = await axios.get(url, { headers });
    return response; // Assuming the API response contains the data in the `data` field
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getReservationDetails = async (userId) => {
  try {
    const TOKEN = localStorage.getItem("AccessToken"); // Get the token from localStorage
    if (!TOKEN) {
      throw new Error("No access token found."); // Throw an error if no token is found
    }

    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };

    // Use the userId in the API endpoint
    const url = `https://api2.platterwise.com/admin-api/user-list/user-reservation-activities/${userId}`;
    const response = await axios.get(url, { headers });
    return response; // Assuming the API response contains the data in the `data` field
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const DeletePost = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };

    const res = await axios.post(
      "https://api2.platterwise.com/admin-api/user-list/remove-user-post",
      data,
      { headers }
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const DeleteUser = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };

    const res = await axios.post(
      "https://api2.platterwise.com/admin-api/user-list/delete-user",
      data,
      { headers }
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const validateEmail = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };

    const res = await axios.post(
      "https://api2.platterwise.com/admin-api/auth/validate-email",
      data,
      { headers }
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const resetPassword = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };

    const res = await axios.post(
      "https://api2.platterwise.com/admin-api/auth/reset-password",
      data,
      { headers }
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const suspendUser = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };

    const res = await axios.post(
      "https://api2.platterwise.com/admin-api/user-list/suspend-user",
      data,
      { headers }
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const activateUser = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };

    const res = await axios.post(
      "https://api2.platterwise.com/admin-api/user-list/activate-suspended-user",
      data,
      { headers }
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createAds = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };

    const res = await axios.post(
      "https://api2.platterwise.com/admin-api/promotional-ads/create",
      data,
      { headers }
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAds = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };

    const res = await axios.post(
      "https://api2.platterwise.com/admin-api/promotional-ads/get-promotional-ad",
      data,
      { headers }
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAllAds = async () => {
  const TOKEN = localStorage.getItem("AccessToken");
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };

    const res = await axios.get(
      "https://api2.platterwise.com/admin-api/promotional-ads/get-all-promotional-ads",
      { headers }
    );
    console.log("alladdsres", res);
    return res.data; // returning res.data which contains the response payload
  } catch (error) {
    console.error(error);
    throw error;
  }
};
