import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createAds, getAds, getAllAds } from "../Services/Api";

const initialState = {
  loading: false,
  isLoading: false,
  getLoading: false,
  error: false,
  adverts: [],
};

const TOKEN = localStorage.getItem("AccessToken");

export const createAdsInfo = createAsyncThunk(
  "adverts/createAdsInfo",
  async (data, thunkAPI) => {
    try {
      const TOKEN = localStorage.getItem("AccessToken");
      const headers = {
        Authorization: `Bearer ${TOKEN}`,
      };

      const res = await createAds(data, headers);
      if (res.error) {
        throw new Error(res.error);
      }
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(
        err.response?.data || "Failed to fetch ads"
      );
    }
  }
);

export const getAllAdsInfo = createAsyncThunk(
  "adverts/getAllAdsInfo",
  async (data, thunkAPI) => {
    try {
      const TOKEN = localStorage.getItem("AccessToken");
      const headers = {
        Authorization: `Bearer ${TOKEN}`,
      };

      const res = await getAllAds(headers);
      console.log("all adds", res);
      if (res.error) {
        throw new Error(res.error);
      }
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(
        err.response?.data || "Failed to fetch ads"
      );
    }
  }
);

export const getAdsInfo = createAsyncThunk(
  "adverts/getAdsInfo",
  async (data, thunkAPI) => {
    try {
      const TOKEN = localStorage.getItem("AccessToken");
      const headers = {
        Authorization: `Bearer ${TOKEN}`,
      };

      const res = await getAds(data, headers);
      if (res.error) {
        throw new Error(res.error);
      }
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(
        err.response?.data || "Failed to fetch ads"
      );
    }
  }
);

const advertSlice = createSlice({
  name: "adverts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createAdsInfo.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createAdsInfo.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.adverts = payload?.data;
        state.error = false;
        console.log(payload);
      })
      .addCase(createAdsInfo.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      .addCase(getAdsInfo.pending, (state) => {
        state.isLoading = true;
        state.error = false;
      })
      .addCase(getAdsInfo.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.adverts = payload?.data;
        state.error = false;
        console.log(payload);
      })
      .addCase(getAdsInfo.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })

      .addCase(getAllAdsInfo.pending, (state) => {
        state.getLoading = true;
        state.error = false;
      })
      .addCase(getAllAdsInfo.fulfilled, (state, { payload }) => {
        state.getLoading = false;
        state.adverts = payload?.data;
        state.error = false;
        console.log(payload);
      })
      .addCase(getAllAdsInfo.rejected, (state) => {
        state.getLoading = false;
        state.error = true;
      });
  },
});

export default advertSlice.reducer;
