import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import Sidebar from "./Components/Dashboard/Sidebar";
import Dashboard from "./Components/Dashboard/Dashboard";
import Signup from "./Components/Auth/Signup";
import Login from "./Components/Auth/Login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Restaurant from "./Components/Restaurants/Restaurant";
import AllReservations from "./Components/Dashboard/AllReservations";
import AllRestaurants from "./Components/Restaurants/AllRestaurants";
import Users from "./Components/Users/Users";
import AllUsers from "./Components/Users/AllUsers";
import UserActivities from "./Components/Users/UserActivities";
import ReservationActivities from "./Components/Users/ReservationActivities";
import LiveCampaigns from "./Components/Advertisement/LiveCampaigns";
import AllCampaigns from "./Components/Advertisement/AllCampaigns";
import Otp from "./Components/Auth/Otp";
import ForgotPassword from "./Components/Auth/ForgotPassword";
import Advertisement from "./Components/Advertisement/Advertisement";

import "./App.css";

function App() {
  return (
    <ChakraProvider>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/otp" element={<Otp />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route
              path="/dashboard/*"
              element={
                <div className="maindashboard__container">
                  <Sidebar />
                  <Routes>
                    <Route index element={<Dashboard />} />
                    <Route path="restaurants" element={<Restaurant />} />
                    <Route path="users" element={<Users />} />
                    <Route path="allusers" element={<AllUsers />} />
                    <Route
                      path="allreservations"
                      element={<AllReservations />}
                    />
                    <Route path="allrestaurants" element={<AllRestaurants />} />
                    <Route
                      path="useractivities/:user_id/*"
                      element={<UserActivities />}
                    />
                    <Route
                      path="reservationactivities/:user_id/*"
                      element={<ReservationActivities />}
                    />
                    <Route path="advertisement" element={<Advertisement />} />
                    <Route path="livecampaigns" element={<LiveCampaigns />} />
                    <Route path="allcampaigns" element={<AllCampaigns />} />
                  </Routes>
                </div>
              }
            />
          </Routes>
        </BrowserRouter>
      </div>
    </ChakraProvider>
  );
}

export default App;
