import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllAdsInfo } from "../../redux/advertInfo";
import AdvertPagination from '../../extraFunctions/AdvertPagination';

const AllCampaigns = () => {
    const dispatch = useDispatch();
    const { adverts } = useSelector((state) => state.advertInfo);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5); // Set the number of items per page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = adverts?.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        dispatch(getAllAdsInfo());
    }, [dispatch]);

    return (
        <div>
            <div className='livecampaign__container'>
                <p className="advert__text livecampaign__text">All Campaigns</p>

                <div className="ads__details">
                    {currentItems?.map((advert) => (
                        <div className="ads__content" key={advert?.id}>
                            <img src={advert?.img_url} className="advert__img" alt="advert__img" />
                            <p className="advert__img__text">{advert?.message}</p>
                        </div>
                    ))}
                </div>
            </div>
            <AdvertPagination
                itemsPerPage={itemsPerPage}
                totalItems={adverts?.length}
                paginate={paginate}
                className="table_pagination"
            />
        </div>
    );
}

export default AllCampaigns;
