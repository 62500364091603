import React, { Component } from 'react';
import Slider from 'react-rangeslider';

class RangeSlider extends Component {
    handleChange = (value) => {
        console.log('Value changed to', value);
        // If you need to do something when the value changes, you can do it here.
    };

    render() {
        // Use the value passed via props
        const { value, max } = this.props;

        return (
            <div className='slider'>
                <Slider
                    min={0}
                    max={max}
                    value={value}
                    onChange={this.handleChange}
                />
                <div className="value">₦{value.toLocaleString()}</div>
            </div>
        );
    }
}

export default RangeSlider;










// import React, { Component } from 'react'
// import Slider from 'react-rangeslider'

// class RangeSlider extends Component {
//     constructor(props, context) {
//         super(props, context)
//         this.state = {
//             value: 50
//         }
//     }

//     handleChangeStart = () => {
//         console.log('Change event started')
//     };

//     handleChange = value => {
//         this.setState({
//             value: value
//         })
//     };

//     handleChangeComplete = () => {
//         console.log('Change event completed')
//     };

//     render() {
//         const { value } = this.state
//         return (
//             <div className='slider'>
//                 <Slider
//                     min={0}
//                     max={100}
//                     value={value}
//                     onChangeStart={this.handleChangeStart}
//                     onChange={this.handleChange}
//                     onChangeComplete={this.handleChangeComplete}
//                 />
//             </div>
//         )
//     }
// }

// export default RangeSlider