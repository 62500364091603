import React from 'react';
import { Card, CardBody } from '@chakra-ui/react';
import Wallet from "../assets/icons/Wallet.svg";
import Reservation from "../assets/icons/Reservation.svg";
import Restaurants from "../assets/icons/Restaurants.svg";
import TotalUsers from "../assets/icons/TotalUsers.svg";
import ActiveUsers from "../assets/icons/ActiveUsers.svg";
import up from "../assets/icons/up.svg";

const CardItem = ({ icon, title, value }) => {
    return (
        <Card size="md" className="first_card">
            <CardBody>
                <div className="wallet__content">
                    <img src={icon} alt="wallet-icon" className="wallet__icon" />
                    <div className="wallet__income">
                        <p className="wallet-text">{title}</p>
                        <p className="wallet-figure">{value}</p>
                    </div>
                </div>
                {/* <div className="wallet-increase">
                    <div className="wallet__increase">
                        <img src={up} alt="wallet-increase" className="up__icon" />
                        <p className="up-text">{increase}</p>
                    </div>
                </div> */}
            </CardBody>
        </Card>
    );
}

const Cards = ({ dashboardData }) => {

    return (
        <div>
            <div className="cards__container">
                <div className="cards__holder">
                    <CardItem
                        icon={Wallet}
                        title="Total Income"
                        value={`₦${(dashboardData?.total_income / 100).toLocaleString()}`}
                    />
                    <CardItem
                        icon={Reservation}
                        title="Total Reservations"
                        value={dashboardData?.total_reservations}
                        increase="+2.02% this week"
                    />
                    <CardItem
                        icon={Restaurants}
                        title="Total Restaurants"
                        value={dashboardData?.total_restaurants}
                    />
                    <CardItem
                        icon={TotalUsers}
                        title="Total Users"
                        value={dashboardData?.total_users}
                    />
                    <CardItem
                        icon={ActiveUsers}
                        title="Active Users"
                        value={dashboardData?.total_active_users}
                    />
                </div>
            </div>
        </div>
    );
}

export default Cards;
