import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useToast } from '@chakra-ui/react';
import BlueKey from "../assets/icons/BlueKey.svg";
import RedKey from "../assets/icons/RedKey.svg";
import CaretDown from "../assets/icons/CaretDown.svg";
import calendar from "../assets/icons/calendar.svg";
import WeeklyChart from "./WeeklyChart";
import { getMonthlyInfo } from '../../redux/dashboardInfo';
import { useSelector, useDispatch } from 'react-redux';


const Example = ({ dashboardData = { users_analytics: {}, reservation_analytics: {} } }) => {
    const toast = useToast();
    const dispatch = useDispatch();

    const [selectedMonth, setSelectedMonth] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const { monthlyReservation } = useSelector((state) => state.dashboardInfo);

    // console.log("dashboardData", dashboardData);

    function showToast(title, message, status) {
        toast({
            title: message,
            status: status,
            duration: 5000,
            isClosable: true,
            position: 'top',
        });
    }

    const monthNames = {
        '1': 'Jan', '2': 'Feb', '3': 'Mar', '4': 'Apr',
        '5': 'May', '6': 'Jun', '7': 'Jul', '8': 'Aug',
        '9': 'Sep', '10': 'Oct', '11': 'Nov', '12': 'Dec'
    };

    const Options = [
        { month: 'January', id: 1, key: 'january' },
        { month: 'February', id: 2, key: 'february' },
        { month: 'March', id: 3, key: 'march' },
        { month: 'April', id: 4, key: 'april' },
        { month: 'May', id: 5, key: 'may' },
        { month: 'June', id: 6, key: 'june' },
        { month: 'July', id: 7, key: 'july' },
        { month: 'August', id: 8, key: 'august' },
        { month: 'September', id: 9, key: 'september' },
        { month: 'October', id: 10, key: 'october' },
        { month: 'November', id: 11, key: 'november' },
        { month: 'December', id: 12, key: 'december' }
    ];

    const handleSelectedMonth = (event) => {
        const month = event.target.value;
        setSelectedMonth(month); // Update the state to the new month
    };

    // Generate the data array for the chart by transforming your analytics data
    const chartData = Object.keys(monthNames).map(month => {
        const userCount = dashboardData?.users_analytics[month] ?? 0;
        const reservationCount = dashboardData?.reservation_analytics[month] ?? 0;
        return {
            name: monthNames[month],
            Users: userCount,
            Reservation: reservationCount
        };
    });

    // The useEffect hook will now have a condition to prevent running without a selected month.
    useEffect(() => {
        if (!selectedMonth) {
            // If no month is selected, don't run the fetchData function.
            return;
        }

        let isSubscribed = true;

        setLoading(true);
        setError(null);

        const TOKEN = localStorage.getItem('AccessToken');
        if (!TOKEN) {
            showToast("Authentication error", "No access token found", "error");
            setError("Authentication error: No access token found.");
            setLoading(false);
            return;
        }

        dispatch(getMonthlyInfo({ month: selectedMonth }))
            .unwrap()
            .then(response => {
                if (isSubscribed || response?.payload?.data?.status_code == 200) {
                    // Handle success here
                    showToast("Success", "dashboard data fetched successfully", "success");
                }
            })
            .catch(error => {
                if (isSubscribed) {
                    setError("Failed to fetch dashboard data");
                    showToast("Error", "Failed to fetch dashboard data", "error");
                }
            })
            .finally(() => {
                if (isSubscribed) {
                    setLoading(false);
                }
            });

        // Cleanup function to prevent memory leaks
        return () => {
            isSubscribed = false;
        };
    }, [selectedMonth]); // This effect depends on selectedMonth only

    // console.log("monthlyReservation", monthlyReservation);


    return (
        <div className="analytics-container">
            <div className="analytics__container">
                <div className="analytics__content">
                    <div className="analytics_content">
                        <p className="analytics__text">Overall Analytics</p>
                    </div>
                    <div className="analytics__keys">
                        <div className="analytics-blue">
                            <img src={BlueKey} alt="blue-icon" className="blue__icon" />
                            <p className="blue__key">Reservation</p>
                        </div>
                        <div className="analytics-blue">
                            <img src={RedKey} alt="red-icon" className="blue__icon" />
                            <p className="blue__key">Users</p>
                        </div>
                        <div className="analytics-blue">
                            <p className="blue__key">Monthly</p>
                            <img src={CaretDown} alt="red-icon" className="caret__icon" />
                        </div>

                    </div>
                </div>
                <ResponsiveContainer width="100%" height={330} margin={{
                    top: 25,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}>
                    <LineChart data={chartData} style={{ marginTop: 10, marginRight: 10 }}>
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="Reservation" stroke="#db4e2e" />
                        <Line type="monotone" dataKey="Users" stroke="#347ae2" />
                    </LineChart>
                </ResponsiveContainer>
            </div>

            <div className="traffic__container">
                <div className="traffic__content">
                    <div className="traffic__reserv">
                        <p className="traffic__start">Reservation Monthly Traffic</p>
                        {/* <p className="traffic_text">20<span className="traffic-txt">reservations</span></p> */}
                    </div>

                    <div className="traffic-calender">
                        <select className="top__restaurant select_filter" value={selectedMonth} onChange={handleSelectedMonth}>
                            <option value="">Select Month</option>
                            {Options.map((option) => (
                                <option key={option.id} value={option.month}>
                                    {option.month}
                                </option>
                            ))}
                            <img src={CaretDown} alt="caret-down" className="top__caret" />
                        </select>
                    </div>

                </div>
                <WeeklyChart monthlyReservation={monthlyReservation} />
            </div>
        </div>
    );
};

export default Example;


