import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDashboardData, getMonthlyReservation } from "../Services/Api";

const initialState = {
  isLoading: false,
  monthlyLoading: false,
  error: false,
  dashboardData: [],
  monthlyReservation: [],
};

const TOKEN = localStorage.getItem("AccessToken");

export const getDashboardInfo = createAsyncThunk(
  "dashboard/getDashboardInfo",
  async (TOKEN, thunkAPI) => {
    try {
      const headers = {
        Authorization: `Bearer ${TOKEN}`,
      };

      const res = await getDashboardData(headers);
      console.log(res);
      if (res.error) {
        // Assuming your API response contains an error field in case of failure
        throw new Error(res.error);
      }
      return res;
    } catch (err) {
      // Use rejectWithValue to pass custom error messages or details to the reducer
      return thunkAPI.rejectWithValue(
        err.message || "Failed to fetch dashboard data"
      );
    }
  }
);

export const getMonthlyInfo = createAsyncThunk(
  "dashboard/getMonthlyInfo",
  async (data, thunkAPI) => {
    try {
      const headers = {
        Authorization: `Bearer ${TOKEN}`,
      };

      const res = await getMonthlyReservation(data, headers);
      if (res.error) {
        // Assuming your API response contains an error field in case of failure
        throw new Error(res.error);
      }
      return res.data; // Assuming the response has a 'data' field with the info you want
    } catch (err) {
      return thunkAPI.rejectWithValue(
        err.response?.data || "Failed to fetch dashboard data"
      );
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardInfo.pending, (state) => {
        state.isLoading = true;
        state.error = false; // Reset error state on new request
      })
      .addCase(getDashboardInfo.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.dashboardData = payload?.data?.data;
        // console.log(payload);
        state.error = false; // Ensure error is reset on successful fetch
      })
      .addCase(getDashboardInfo.rejected, (state, action) => {
        state.isLoading = false;
        // Update to store custom error message
        state.error = action.payload || "Something went wrong";
      })

      .addCase(getMonthlyInfo.pending, (state) => {
        state.monthlyLoading = true;
        state.error = false; // Reset error state on new request
      })
      .addCase(getMonthlyInfo.fulfilled, (state, { payload }) => {
        state.monthlyLoading = false;
        state.monthlyReservation = payload?.data;
        // console.log(payload);
        state.error = false; // Ensure error is reset on successful fetch
      })
      .addCase(getMonthlyInfo.rejected, (state, action) => {
        state.monthlyLoading = false;
        // Update to store custom error message
        state.error = action.payload || "Something went wrong";
      });
  },
});

export default dashboardSlice.reducer;
