import React, { useState } from 'react';
import { PiDotsThreeVertical } from "react-icons/pi";
import { useDisclosure } from "@chakra-ui/react";
import SuspendUserModal from './SuspendUserModal';
import RemovePost from './RemovePost';

const ActivityDropdown = ({ user_id, post_id }) => {
    const [toggle, setToggle] = useState(false);
    const { isOpen: isOpenCard, onOpen: onOpenCard, onClose: onCloseCard } = useDisclosure();
    const { isOpen: isOpenPost, onOpen: onOpenPost, onClose: onClosePost } = useDisclosure();


    return (
        <div className="dropdown_container">
            <button
                className="reservation_action"
                onClick={() => {
                    setToggle(!toggle);
                }}
            >
                <PiDotsThreeVertical className="three-dots" />
            </button>
            <div
                className="userdropdown__content activity__dropdown"
                style={{ display: toggle ? 'block' : 'none' }}
            >
                <p
                    className="dropdown__text users__text"
                    onClick={onOpenPost}
                >Remove Post</p>
                <p className="dropdown__text users__text" onClick={onOpenCard}>Suspend user</p>
            </div>
            <SuspendUserModal isOpen={isOpenCard} onOpen={onOpenCard} onClose={onCloseCard} user_id={user_id} />
            <RemovePost isOpen={isOpenPost} onOpen={onOpenPost} onClose={onClosePost} user_id={user_id} post_id={post_id} />
        </div>
    );
}

export default ActivityDropdown;
