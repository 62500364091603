import React, { useState } from 'react';
import { Button, useToast, Input } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Background from "../assets/icons/Background.svg";
import { FiEye } from "react-icons/fi";
import { FaRegEyeSlash } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { signupUser } from "../../redux/userInfo";

const Signup = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [isloading, setIsLoading] = useState(false);
    const [full_name, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    function showToast(title, message, status) {
        toast({
            title: message,
            status: status,
            duration: 5000,
            isClosable: true,
            position: 'top',
        });
    }

    const data = {
        full_name: full_name,
        email: email,
        password: password,
    };

    const handleClick = () => setShow(!show);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'full_name') {
            setFullName(value);
        } else if (name === 'email') {
            setEmail(value);
        } else if (name === 'password') {
            setPassword(value);
        }
    };

    const handleSignup = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await dispatch(signupUser(data));
            console.log(response);

            if (response?.payload?.data?.status_code === 200) {
                setIsLoading(false);
                localStorage.setItem('AccessToken', response?.payload?.data?.token?.original?.access_token);
                showToast('Success', 'Signup successful', 'success');
                navigate('/dashboard');
            } else if (response?.payload?.data?.status_code === 401) {
                setIsLoading(false);
                const errorData = response?.payload?.data?.data?.[0];
                if (errorData) {
                    showToast('Error', errorData, 'error');
                } else {
                    showToast('Error', 'An error occurred', 'error');
                }
            }
        } catch (error) {
            setIsLoading(false);
            if (error.response) {
                showToast('Error', error.response.data.status, 'error');
            }
        }
    };


    return (
        <div>
            <div className="auth__container">
                <div className="auth__content">
                    <div className="signin-text">
                        <p className="signin-admin">Create Account</p>
                    </div>
                    <div className="login__form">
                        <p className="text__name">FullName</p>
                        <Input
                            type="text"
                            placeholder='Enter your fullname'
                            id="login__input"
                            name="full_name"
                            onChange={handleChange}
                        />

                        <p className="text__name">Email</p>
                        <Input
                            type="text"
                            placeholder='Enter your email'
                            id="login__input"
                            name="email"
                            onChange={handleChange}
                        />

                        <p className="text__name">Password</p>
                        <Input
                            type={show ? 'text' : 'password'}
                            placeholder='Enter your password'
                            id="login__input"
                            className='password'
                            name="password"
                            onChange={handleChange}
                        />
                        {show ? <FaRegEyeSlash className="password__icon_i" onClick={handleClick} /> : <FiEye className="password__icon_i" onClick={handleClick} />}

                        <Button
                            id='auth_button'
                            isLoading={isloading}
                            loadingText="Verifying your credentials ..."
                            onClick={handleSignup}
                        >
                            Sign Up
                        </Button>
                    </div>

                    <div className="login_account">
                        <p className="login__account__text">
                            Have an account? <Link to={"/"}><span className="login__span">Sign in</span></Link>
                        </p>

                    </div>
                </div>

                <div className="auth__image">
                    <img src={Background} alt="background-img" className="background-img" />
                </div>

            </div>
        </div>
    );
}

export default Signup;

