import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import {
  getRestaurantData,
  getRestaurantAnalysis,
  getTopPerforming,
  getSalesRevenue,
} from "../Services/Api";

const initialState = {
  isLoading: false,
  loading: false,
  performanceLoading: false,
  salesLoading: false,
  error: false,
  restaurantData: [],
  restaurantAnalysis: [],
  performanceData: [],
  salesRevenue: [],
};

const TOKEN = localStorage.getItem("AccessToken");

export const getRestaurantInfo = createAsyncThunk(
  "restaurant/getRestaurantInfo",
  async (TOKEN, thunkAPI) => {
    try {
      const headers = {
        Authorization: `Bearer ${TOKEN}`,
      };

      const res = await getRestaurantData(headers);
      if (res.error) {
        // Assuming your API response contains an error field in case of failure
        throw new Error(res.error);
      }
      return res;
    } catch (err) {
      // Use rejectWithValue to pass custom error messages or details to the reducer
      return thunkAPI.rejectWithValue(
        err.message || "Failed to fetch restaurant data"
      );
    }
  }
);

export const getRestaurantAnalysisInfo = createAsyncThunk(
  "restaurant/getRestaurantAnalysisInfo",
  async (TOKEN, thunkAPI) => {
    try {
      const headers = {
        Authorization: `Bearer ${TOKEN}`,
      };

      const res = await getRestaurantAnalysis(headers);
      if (res.error) {
        // Assuming your API response contains an error field in case of failure
        throw new Error(res.error);
      }
      return res;
    } catch (err) {
      return thunkAPI.rejectWithValue(
        err.message || "Failed to fetch restaurant data"
      );
    }
  }
);

export const getTopPerformanceInfo = createAsyncThunk(
  "restaurant/getTopPerformanceInfo",
  async (TOKEN, thunkAPI) => {
    try {
      const headers = {
        Authorization: `Bearer ${TOKEN}`,
      };

      const res = await getTopPerforming(headers);
      if (res.error) {
        // Assuming your API response contains an error field in case of failure
        throw new Error(res.error);
      }
      return res;
    } catch (err) {
      return thunkAPI.rejectWithValue(
        err.message || "Failed to fetch restaurant data"
      );
    }
  }
);

export const getSalesInfo = createAsyncThunk(
  "restaurant/getSalesInfo",
  async (data, thunkAPI) => {
    try {
      const headers = {
        Authorization: `Bearer ${TOKEN}`,
      };

      const res = await getSalesRevenue(data, headers);
      if (res.error) {
        // Assuming your API response contains an error field in case of failure
        throw new Error(res.error);
      }
      return res.data; // Assuming the response has a 'data' field with the info you want
    } catch (err) {
      return thunkAPI.rejectWithValue(
        err.response?.data || "Failed to fetch restaurant data"
      );
    }
  }
);

const restaurantSlice = createSlice({
  name: "restaurant",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRestaurantInfo.pending, (state) => {
        state.isLoading = true;
        state.error = false;
      })
      .addCase(getRestaurantInfo.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.restaurantData = payload?.data?.data;
        state.error = false;
      })
      .addCase(getRestaurantInfo.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })

      .addCase(getRestaurantAnalysisInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRestaurantAnalysisInfo.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.restaurantAnalysis = payload?.data?.data;
      })
      .addCase(getRestaurantAnalysisInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Something went wrong";
      })

      .addCase(getTopPerformanceInfo.pending, (state) => {
        state.performanceLoading = true;
      })
      .addCase(getTopPerformanceInfo.fulfilled, (state, { payload }) => {
        state.performanceLoading = false;
        state.performanceData = payload?.data?.data;
      })
      .addCase(getTopPerformanceInfo.rejected, (state, action) => {
        state.performanceLoading = false;
        state.error = action.payload || "Something went wrong";
      })

      .addCase(getSalesInfo.pending, (state) => {
        state.salesLoading = true;
      })
      .addCase(getSalesInfo.fulfilled, (state, { payload }) => {
        state.salesLoading = false;
        state.salesRevenue = payload?.data;
        // console.log(payload);
        // console.log(current(state));
      })
      .addCase(getSalesInfo.rejected, (state, action) => {
        state.salesLoading = false;
        state.error = action.payload || "Something went wrong";
      });
  },
});

export default restaurantSlice.reducer;
