import React, { useState } from 'react';
import User from "../assets/icons/User.svg";
import message from "../assets/icons/message.svg";
import notification from "../assets/icons/notification.svg";
import { CiSearch } from "react-icons/ci";
import { MdLogout } from 'react-icons/md';
import Settings from "../assets/icons/Settings.svg"
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const [toggle, setToggle] = useState(false);
    const navigate = useNavigate();

    return (
        <div>
            <div className='header__container'>
                <div className="header__content">
                    <div className="header__text">
                        <p className="header-text">Welcome, Admin</p>
                    </div>

                    <div className="header__context">
                        <div className="header__input">
                            <CiSearch className="search__icon" />
                            <input type="search" className="header__search" placeholder="Search here..." />
                        </div>
                        <div className="header__icons">
                            <img src={notification} alt="notification__icon" className='notification_icon' />
                            <img src={message} alt="message__icon" className="message__icon" />
                            <img src={User}
                                alt="user__icon"
                                className="user__icon"
                                onClick={() => {
                                    setToggle(!toggle);
                                }}
                            />
                        </div>
                    </div>
                    {toggle && <div className="header__overlay" onClick={() => setToggle(false)}></div>}

                    <div
                        className="header__dropdown"
                        style={{ display: toggle ? 'block' : 'none' }}
                    >
                        <div className="nheader__dropdown__content ">
                            <div className="header__dropdown__icon">
                                <div className="header__dropdown__icon_i">
                                    <div className="header__dropdown__icon__content">
                                        <MdLogout className="header__dropdown__icon__logout" />
                                    </div>
                                    <p
                                        className="header__dropdown__icon__text"
                                        onClick={() => {
                                            localStorage.removeItem('AccessToken');
                                            navigate('/');
                                        }}
                                    >Logout</p>
                                </div>
                                <div className="header__dropdown__icon_i">

                                    <div className="header__dropdown__icon__content">
                                        <img src={Settings} alt="dashboard__icon" className="header__dropdown__icon__logout" />
                                    </div>
                                    <p
                                        className="header__dropdown__icon__text set"
                                    >Settings</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default Header;
