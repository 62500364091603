import React, { useState } from 'react';
import UsersPagination from '../../extraFunctions/UsersPagination';
import UsersDropdown from '../../extraFunctions/UsersDropdown';
import { useNavigate } from 'react-router-dom';

const UserTable = ({ allUsers, usersData }) => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('active'); // 'active' or 'inactive'
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(8); // Set the number of items per page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = allUsers?.data?.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // console.log("allUsers", allUsers);

    return (
        <div className="reservation__table__container">
            <div className="userlist">
                <p className="userlist__text">Userlist</p>
            </div>
            <div className='tabs'>
                <button
                    className={`tab ${activeTab === 'active' ? 'active' : ''}`}
                    onClick={() => setActiveTab('active')}
                >
                    Active users ({usersData?.total_active_users})
                </button>
                <button
                    className={`tab ${activeTab === 'inactive' ? 'active' : ''}`}
                    onClick={() => setActiveTab('inactive')}>
                    Inactive users ({usersData?.total_inactive_users})
                </button>
            </div>

            <div className="userstable__container">
                <table className='table__container table_users'>
                    <thead>
                        <tr>
                            <th className='table__header__text'>SN</th>
                            <th className='table__header__text'>Full name</th>
                            <th className='table__header__text'>Username</th>
                            <th className='table__header__text'>User ID</th>
                            <th className='table__header__text'>Contacts</th>
                            <th className='table__header__text'>Completed reservations</th>
                            <th className='table__header__text'>Completed posts</th>
                            <th className='table__header__text'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItems?.map((data, index) => (
                                <tr className="reservation__table__rw" key={index + 1}>
                                    <td className="reservation__table__first">{index + 1}</td>
                                    <td className="reservation__table__sec">{data?.full_name}</td>
                                    <td className="reservation__table__sec">{data?.username}</td>
                                    <td className="reservation__table__sec">{data?.id}</td>
                                    <td className="reservation__table__sec">{data?.phone}</td>
                                    <td className="reservation__table__sec">{data?.completed_reservation}</td>
                                    <td className="reservation__table__sec">{data?.total_posts}</td>
                                    <UsersDropdown DataID={data?.id} />
                                </tr>

                            ))
                        }
                    </tbody>
                </table>

                <p
                    className="view__all_rest"
                    onClick={() => {
                        navigate("/dashboard/allusers")
                    }}
                >View all</p>
                <UsersPagination
                    itemsPerPage={itemsPerPage}
                    totalItems={allUsers?.data?.length}
                    paginate={paginate}
                    className="table_pagination"
                />
            </div>


        </div>
    );
}

export default UserTable;
