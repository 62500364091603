import React, { useState, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import * as XLSX from 'xlsx';
import ArrowLeft from "../assets/icons/arrow-left.svg";
import filter from "../assets/icons/filter.svg";
import download from "../assets/icons/download.svg";
import CaretDown from "../assets/icons/CaretDown.svg"
import Dropdown from "../../extraFunctions/Dropdown";
import moment from 'moment';
import ReservationPagination from '../../extraFunctions/ReservationPagination';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getDashboardInfo } from '../../redux/dashboardInfo';

const AllReservations = () => {
    const toast = useToast();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { dashboardData } = useSelector((state) => state.dashboardInfo);
    const [selectedOption, setSelectedOption] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(15); // Set the number of items per page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = dashboardData?.recent_reservation?.slice(indexOfFirstItem, indexOfLastItem);
    const [filterQuery, setFilterQuery] = useState('');

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const TOKEN = localStorage.getItem('AccessToken');

    function showToast(title, message, status) {
        toast({
            title: message,
            status: status,
            duration: 5000,
            isClosable: true,
            position: 'top',
        });
    }

    useEffect(() => {
        dispatch(getDashboardInfo());
    }, [])

    // console.log("reservationData", reservationsData);

    const pendingStyle = {
        color: "#AD3307",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        width: "140px",
        height: "40px",
        borderRadius: "8px",
        background: "#FFECE5",
        fontWeight: "600",
        fontSize: "14px",
        fontFamily: "Poppins"
    }

    const approvedStyle = {
        color: "#0F973D",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        width: "140px",
        height: "40px",
        borderRadius: "8px",
        background: "#E7F6EC",
        fontWeight: "600",
        fontSize: "14px",
        fontFamily: "Poppins"
    }

    const rejectedStyle = {
        color: "#D42620",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        width: "140px",
        height: "40px",
        borderRadius: "8px",
        background: "#FBEAE9",
        fontWeight: "600",
        fontSize: "14px",
        fontFamily: "Poppins"
    }

    const successStyle = {
        color: "#0F973D",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        width: "140px",
        height: "40px",
        borderRadius: "8px",
        background: "#E7F6EC",
        fontWeight: "600",
        fontSize: "14px",
        fontFamily: "Poppins"
    }

    const inprogressStyle = {
        color: "#AD3307",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        width: "140px",
        height: "40px",
        borderRadius: "8px",
        background: "#FFECE5",
        fontWeight: "600",
        fontSize: "14px",
        fontFamily: "Poppins"
    }

    const BillStyle = {
        color: "black",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        width: "140px",
        height: "40px",
        borderRadius: "8px",
        background: "#FFECE5",
        fontWeight: "600",
        fontSize: "14px",
        fontFamily: "Poppins"
    }

    const processingStyle = {
        color: "cyan",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        width: "140px",
        height: "40px",
        borderRadius: "8px",
        background: "red",
        fontWeight: "600",
        fontSize: "14px",
        fontFamily: "Poppins"
    }

    const options = [
        { name: 'Restaurants name', id: 1, key: 'name' },
        { name: 'Customers name', id: 2, key: 'customers name' },
        { name: 'Reservation status', id: 3, key: 'status' }
    ];

    const handleSelectedOption = (event) => {
        setSelectedOption(event.target.value);
        setFilterQuery(''); // Clear filter query on selection change
    };

    const getStatusText = (statusCode) => {
        const statusMap = {
            0: 'Rejected',
            1: 'Pending',
            2: 'Approved',
            3: 'In Progress',
            4: 'Completed',
            5: 'Bill Generated',
            // Add other status codes and descriptions as necessary
        };

        return statusMap[statusCode] || 'Unknown Status'; // Default to 'Unknown Status'
    };


    const filteredItems = currentItems?.filter(item => {
        const allGuests = JSON.parse(item.all_guests || '[]');
        const customerNames = allGuests.map(guest => guest.name.toLowerCase());
        const restaurantName = item.restaurant?.name.toLowerCase();

        switch (selectedOption) {
            case 'Customers name':
                return customerNames.some(name => name.includes(filterQuery.toLowerCase()));
            case 'Restaurants name':
                return restaurantName && restaurantName.includes(filterQuery.toLowerCase());
            case 'Reservation status':
                const statusText = getStatusText(item.status); // Use the function here
                return statusText.toLowerCase().includes(filterQuery.toLowerCase());
            default:
                return true; // No filtering if no valid option is selected
        }
    });

    const exportToExcel = () => {
        // Process each item to structure it for the Excel file
        const processedData = filteredItems.map(item => {
            // Parse the all_guests JSON string into an array
            const allGuestsArray = JSON.parse(item.all_guests || '[]');

            // Map over the parsed array to get guest names (adjust based on your actual data structure)
            const guestNames = allGuestsArray.map(guest => guest.name).join(", ");

            return {
                'Customer Names': guestNames,
                'Reservation ID': item.id,
                'Date': moment(item.reservation_date).format('YYYY-MM-DD HH:mm:ss'),
                "Restaurant's Name": item.restaurant?.name,
                'Location': item.restaurant?.address,
                'Reservation Status': getStatusText(item.status),
            };
        });

        const ws = XLSX.utils.json_to_sheet(processedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Reservations");
        XLSX.writeFile(wb, "reservations.xlsx");
    };


    return (
        <div className="all__container">
            <div
                className="all__arrow"
                onClick={() => {
                    navigate(-1)
                }}
            >
                <img src={ArrowLeft} alt="arrow-left" className='arrow-left' />
            </div>

            <div className="all__content">
                <div className="all__text">
                    <p className="all__recent">Recent Reservations</p>
                </div>
                <div className='all__fliter'>
                    <select className="top__restaurant select_filter" value={selectedOption} onChange={handleSelectedOption}>
                        <option value="">Filter</option>
                        {options.map((option) => (
                            <option key={option.id} value={option.name}>
                                {option.name}
                            </option>
                        ))}
                        <img src={filter} alt="filter-icon" className="filter__icon" />
                        <img src={CaretDown} alt="caret-down" className="top__caret" />
                    </select>

                    <input
                        type="text"
                        value={filterQuery}
                        onChange={(e) => setFilterQuery(e.target.value)}
                        placeholder="Type to filter..."
                        className="filter-input" // Add appropriate classes as needed
                    />
                    {/* <div className="filter__content">
                        <img src={filter} alt="filter-icon" className="filter__icon" />
                        <p className="filter-text">Filter</p>
                    </div> */}
                    {/* <div className="export__content">
                        <img src={download} alt="download-icon" className="download__icon" />
                        <p className="export-text">Export CSV</p>
                    </div> */}

                    <div className="export__content" onClick={exportToExcel}>
                        <img src={download} alt="download-icon" className="download__icon" />
                        <p className="export-text">Export to Excel</p>
                    </div>
                </div>
            </div>

            <div className="all__reservation__container">
                <div className="all__recent__content">
                    <div className="recent__reservation__text">
                        <p className="recent__reservation__activity">Recent Reservation Activities</p>
                    </div>
                    <div className="recent__reservation__calendar">
                        <p className="all__calendar__text">February</p>
                        <img src={CaretDown} alt="caret_down" className="reservation-caret" />
                    </div>
                </div>

                <table className='table__container'>
                    <thead>
                        <tr>
                            <th className='table__header__text'>NO</th>
                            <th className='table__header__text'>Customers Names</th>
                            <th className='table__header__text'>Reservation ID</th>
                            <th className='table__header__text'>Date</th>
                            <th className='table__header__text'>Restaurant's Name</th>
                            <th className='table__header__text'>Location</th>
                            <th className='table__header__text'>Reservation Status</th>
                            <th className='table__header__text'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredItems?.map((data, index) => {
                            // Parse the JSON string representing the all_guests array into an actual array of objects
                            const allGuests = JSON.parse(data.all_guests);

                            // Access the first name property of the first guest
                            const customerName = allGuests.length > 0 ? allGuests[0].name.split(' ')[0] : ''; // Get the first name

                            return (
                                <tr className="reservation__table__rw" key={index + 1}>
                                    <td className="reservation__table__first">{index + 1}</td>
                                    <td className="reservation__table__sec">{customerName}</td> {/* Updated this line */}
                                    <td className="reservation__table__sec">{data?.id}</td>
                                    <td className="reservation__table__sec">{moment(data?.reservation_date).format('YYYY-MM-DD HH:mm:ss')}</td>
                                    <td className="reservation__table__sec">{data?.restaurant?.name}</td>
                                    <td className="reservation__table__sec">{data?.restaurant?.address}</td>
                                    <td>
                                        {data.status === 1 && <span style={pendingStyle}>Pending</span>}
                                        {data.status === 2 && <span style={approvedStyle}>Approved</span>}
                                        {data.status === 0 && <span style={rejectedStyle}>Rejected</span>}
                                        {data.status === 4 && <span style={successStyle}>Completed</span>}
                                        {data.status === 3 && <span style={inprogressStyle}>In Progress</span>}
                                        {data.status === 5 && <span style={BillStyle}>Bill Generated</span>}
                                        {data.reservation_status === "split_bill" && <span style={processingStyle}>Accepted</span>}
                                    </td>
                                    <Dropdown reservationData={data} />
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <ReservationPagination
                    itemsPerPage={itemsPerPage}
                    totalItems={dashboardData?.recent_reservation?.length}
                    paginate={paginate}
                    className="table_pagination"
                />
            </div>
        </div>
    );
}

export default AllReservations;
