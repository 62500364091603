import React, { useState, useEffect } from 'react';
import ArrowLeft from "../assets/icons/arrow-left.svg";
import BoxProfile from "../assets/icons/BoxProfile.svg";
import BoxDropdown from '../../extraFunctions/BoxDropdown';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { getReservationDetailsInfo, getUsersViewInfo } from "../../redux/usersInfo";
import moment from "moment";
import ReservationDetailsPagination from '../../extraFunctions/ReservationDetailsPagination';

const ReservationActivities = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    let { user_id } = useParams();

    console.log("user_id", user_id);

    const [activeTab, setActiveTab] = useState('active');
    const usersViewList = useSelector(state => state.usersInfo.usersViewList);
    const { reservationDetails } = useSelector((state) => state.usersInfo);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(6); // Set the number of items per page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = reservationDetails?.data?.slice(indexOfFirstItem, indexOfLastItem);


    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        // Update the activeTab state based on the current path
        const path = location.pathname;
        if (path.includes(`/dashboard/useractivities/${user_id}`)) {
            setActiveTab('active');
        } else if (path.includes(`/dashboard/reservationactivities/${user_id}`)) {
            setActiveTab('inactive');
        }
    }, [location, user_id]); // Run the effect on location and user_id changes

    useEffect(() => {
        if (user_id) {
            dispatch(getUsersViewInfo({ user_id: user_id }));
            dispatch(getReservationDetailsInfo(user_id));
        }
    }, [user_id, dispatch]);

    console.log("usersViewList", usersViewList);
    console.log("reservationDetails", reservationDetails);


    return (
        <div className="activities__container">
            <div className="arrow__back">
                <img src={ArrowLeft} className="arrow-left" alt="back-icon"
                    onClick={() => {
                        navigate(-1)
                    }}
                />
                <p className="activities__text">User Activities</p>
            </div>

            <div className="activities__box">
                <div className="box__content">
                    <div className="box__profile">
                        {
                            usersViewList?.profile?.profileUrl ?
                                <img src={usersViewList.profile.profileUrl} className="box-profile first__image" alt="profile-img" /> :
                                <img src={BoxProfile} className="box-profile first__image" alt="default-profile-img" />
                        }
                        {/* <img src={BoxProfile} className="box-profile" alt="profile-img" /> */}
                        <div className="box__name">
                            <p className="box-name">{usersViewList?.profile?.full_name}</p>
                            <p className="box-at">{usersViewList?.profile?.username}</p>
                        </div>
                    </div>
                    <div className="box__account">
                        <div className="box__created">
                            <p className="box-account">Account created:</p>
                            {/* <p className="box-account">Total time spent:</p> */}
                        </div>
                        <div className="account__values">
                            <p className="box-values box">{moment(usersViewList?.profile?.created_at).format('MMMM Do YYYY')}</p>
                            {/* <p className="box-values box_i">100 hours</p> */}
                        </div>
                    </div>
                    <div className="box__dots">
                        <BoxDropdown />
                    </div>
                </div>
            </div>
            <div className="activity__indicator">
                <button
                    className={`tab_i ${activeTab === 'active' ? 'active' : ''}`}
                    onClick={() => {
                        navigate(`/dashboard/useractivities/${user_id}`);
                    }}
                >Community Activities
                </button>
                <button
                    className={`tab_i ${activeTab === 'inactive' ? 'active' : ''}`}
                    onClick={() => {
                        navigate(`/dashboard/reservationactivities/${user_id}`);
                    }}
                >
                    Reservation Activities
                </button>
            </div>

            <div className="userstable__container reservation__details">
                <table className='table__container table_users'>
                    <thead>
                        <tr>
                            <th className='table__header__text'>SN</th>
                            <th className='table__header__text'>Restaurant name</th>
                            <th className='table__header__text'>Date</th>

                            <th className='table__header__text'>Guests</th>
                            <th className='table__header__text'>Reservation Type</th>
                            <th className='table__header__text'>Amount Paid</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItems?.map((data, index) => (
                                <tr className="reservation__table__rw" key={index + 1}>
                                    <td className="reservation__table__first">{index + 1}</td>
                                    <td className="reservation__table__sec">{data?.restaurant?.name}</td>
                                    <td className="reservation__table__sec">{moment(data?.created_at).format('MMMM Do YYYY')}</td>
                                    <td className="reservation__table__sec">{data?.guest_no}</td>
                                    <td className="reservation__table__sec">{data?.seat_type}</td>
                                    {
                                        data?.reservation_bill === null ? <td className="reservation__table__sec">0</td> :
                                            <td className="reservation__table__sec">{data?.reservation_bill?.amount_paid / 100}</td>
                                    }

                                </tr>

                            ))
                        }
                    </tbody>
                </table>
                <ReservationDetailsPagination
                    itemsPerPage={itemsPerPage}
                    totalItems={reservationDetails?.data?.length}
                    paginate={paginate}
                    className="table_pagination"
                />
            </div>
        </div>
    );
}

export default ReservationActivities;
