import React, { useState } from 'react';
import Platterwise from "../assets/icons/Platterwise.svg"
import Dashboard from "../assets/icons/Dashboard.svg"
import Restaurant from "../assets/icons/Restaurant.svg"
import Users from "../assets/icons/Users.svg"
import Settings from "../assets/icons/Settings.svg"
import Receipt from "../assets/icons/Users.svg"
import { Link } from 'react-router-dom';

const Sidebar = () => {
    const [selectedItem, setSelectedItem] = useState('dashboard');

    const handleItemClick = (item) => {
        setSelectedItem(item);
    };

    return (
        <div>
            <div className="sidebar__container">
                <div className="sidebar__content">
                    <div className="sidebar__logo">
                        <img src={Platterwise} alt="platterwise__logo" className="logo" />
                        <p className='logo__text'>tabilli</p>
                    </div>
                    <Link to={""}>
                        <div
                            className={`sidebar__dashboard ${selectedItem === 'dashboard' ? 'selected' : ''}`}
                            onClick={() => handleItemClick('dashboard')}
                        >
                            <img src={Dashboard} alt="dashboard__icon" className={selectedItem === 'dashboard_icon' ? 'selected' : ''} />
                            <p className={`sidebar__text ${selectedItem === 'dashboard' ? 'selected' : ''}`}>Dashboard</p>
                        </div>
                    </Link>
                    <Link to={"restaurants"}>
                        <div
                            className={`sidebar__dashboard ${selectedItem === 'restaurants' ? 'selected' : ''}`}
                            onClick={() => handleItemClick('restaurants')}
                        >
                            <img src={Restaurant} alt="dashboard__icon" className={selectedItem === 'dashboard_icon' ? 'selected' : ''} />
                            <p className={`sidebar__text ${selectedItem === 'restaurants' ? 'selected' : ''}`}>Restaurant</p>
                        </div>
                    </Link>
                    <Link to={"users"}>
                        <div
                            className={`sidebar__dashboard ${selectedItem === 'users' ? 'selected' : ''}`}
                            onClick={() => handleItemClick('users')}
                        >
                            <img src={Users} alt="dashboard__icon" className={selectedItem === 'dashboard_icon' ? 'selected' : ''} />
                            <p className={`sidebar__text ${selectedItem === 'users' ? 'selected' : ''}`}>Users</p>
                        </div>
                    </Link>

                    {/* <div
                        className={`sidebar__dashboard ${selectedItem === 'settings' ? 'selected' : ''}`}
                        onClick={() => handleItemClick('settings')}
                    >
                        <img src={Settings} alt="dashboard__icon" className={selectedItem === 'dashboard_icon' ? 'selected' : ''} />
                        <p className={`sidebar__text ${selectedItem === 'settings' ? 'selected' : ''}`}>Settings</p>
                    </div> */}
                    <Link to={"advertisement"}>
                        <div
                            className={`sidebar__dashboard ${selectedItem === 'advertisement' ? 'selected' : ''}`}
                            onClick={() => handleItemClick('advertisement')}
                        >
                            <img src={Receipt} alt="dashboard__icon" className={selectedItem === 'dashboard_icon' ? 'selected' : ''} />
                            <p className={`sidebar__text ${selectedItem === 'advertisement' ? 'selected' : ''}`}>Advertisement</p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
