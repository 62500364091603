import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    Avatar,
} from '@chakra-ui/react';
import Profile from "../assets/icons/Profile.svg";
import moment from 'moment';

const ReservationDetails = ({ isOpen, onClose, reservationData }) => {

    console.log("modalData", reservationData)

    const allGuests = JSON.parse(reservationData?.all_guests || '[]');

    const firstGuestEmail = allGuests.length > 0 ? allGuests[0].email : 'No email provided';

    const pendingStyle = {
        color: "#AD3307",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        width: "140px",
        height: "40px",
        borderRadius: "8px",
        background: "#FFECE5",
        fontWeight: "600",
        fontSize: "14px",
        fontFamily: "Poppins"
    }

    const approvedStyle = {
        color: "#0F973D",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        width: "140px",
        height: "40px",
        borderRadius: "8px",
        background: "#E7F6EC",
        fontWeight: "600",
        fontSize: "14px",
        fontFamily: "Poppins"
    }

    const rejectedStyle = {
        color: "#D42620",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        width: "140px",
        height: "40px",
        borderRadius: "8px",
        background: "#FBEAE9",
        fontWeight: "600",
        fontSize: "14px",
        fontFamily: "Poppins"
    }

    const successStyle = {
        color: "#0F973D",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        width: "140px",
        height: "40px",
        borderRadius: "8px",
        background: "#E7F6EC",
        fontWeight: "600",
        fontSize: "14px",
        fontFamily: "Poppins"
    }

    const inprogressStyle = {
        color: "#AD3307",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        width: "140px",
        height: "40px",
        borderRadius: "8px",
        background: "#FFECE5",
        fontWeight: "600",
        fontSize: "14px",
        fontFamily: "Poppins"
    }

    const BillStyle = {
        color: "black",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        width: "140px",
        height: "40px",
        borderRadius: "8px",
        background: "#FFECE5",
        fontWeight: "600",
        fontSize: "14px",
        fontFamily: "Poppins"
    }

    const processingStyle = {
        color: "cyan",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        width: "140px",
        height: "40px",
        borderRadius: "8px",
        background: "red",
        fontWeight: "600",
        fontSize: "14px",
        fontFamily: "Poppins"
    }
    return (
        <div>
            <td
                className="reservation__table__sec"
            >
                <Modal
                    isOpen={isOpen}
                    onClose={onClose}
                    isCentered
                    id="reservation__modal"
                    size="xl"
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader className="reservation__header__text"></ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <div className='modal_header'>
                                <Avatar src={Profile} className="avatar__image" />
                                <div className="modal__header__text">
                                    {allGuests.map((guest, index) => (
                                        <p className="modal-text" key={index}>
                                            {guest.name}
                                        </p>
                                    ))}
                                    <p className='modal__id'>Host ID: {reservationData?.id}</p>
                                </div>
                            </div>

                            <div className="modal_contacts">
                                <div className="modal__phone">
                                    <p className="modal-phone-text">Phone Number</p>
                                    <p className="modal-phone-num">{reservationData?.restaurant?.phone}</p>
                                </div>

                                <div className="modal__email">
                                    <p className="modal-phone-text">Email Address</p>
                                    <p className="modal-phone-num">{firstGuestEmail}</p>
                                </div>
                            </div>

                            <div className="modal__details">
                                <p className="modal__details_header">
                                    Reservation Details
                                </p>
                                <div className="modal-details-content">
                                    <div className="modal-details-first">
                                        <p className="modal-date">Date</p>
                                        <p className="modal-date-text">{moment(reservationData?.reservation_date).format('YYYY-MM-DD')}</p>
                                    </div>

                                    <div className="modal-details-first">
                                        <p className="modal-date">Time</p>
                                        <p className="modal-date-text">{moment(reservationData?.reservation_date).format('HH:mm:ss')}</p>
                                    </div>

                                    <div className="modal-details-first">
                                        <p className="modal-date">Seat</p>
                                        <p className="modal-date-text">{reservationData?.seat_type}</p>
                                    </div>

                                    <div className="modal-details-first">
                                        <p className="modal-date">Guest</p>
                                        <p className="modal-date-text">{reservationData?.
                                            guest_no} Guests</p>
                                    </div>
                                </div>
                            </div>

                            <div className="modal__details">
                                <p className="modal__details_header">
                                    Reservation Status
                                </p>
                                {
                                    reservationData?.status === 1 && <span style={pendingStyle}>Pending</span>
                                }
                                {reservationData?.status === 2 && <span style={approvedStyle}>Approved</span>}
                                {reservationData?.status === 0 && <span style={rejectedStyle}>Rejected</span>}
                                {reservationData?.status === 4 && <span style={successStyle}>Completed</span>}
                                {reservationData?.status === 3 && <span style={inprogressStyle}>In Progress</span>}
                                {reservationData?.status === 5 && <span style={BillStyle}>Bill Generated</span>}

                            </div>

                        </ModalBody>
                    </ModalContent>
                </Modal>
            </td>
        </div>
    );
}

export default ReservationDetails;
