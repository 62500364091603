import React, { useState, useEffect } from 'react';
import { PiDotsThreeVertical } from "react-icons/pi";
import { useDisclosure } from "@chakra-ui/react";
import SuspendUserModal from './SuspendUserModal';
import DeleteUserModal from "./DeleteUserModal";
import ActivateUserModal from './ActivateUserModal';
import { useNavigate } from 'react-router-dom';
import { getUsersViewInfo, getUsersPostsInfo } from '../redux/usersInfo';
import { useToast } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';

const UsersDropdown = ({ DataID }) => {
    const [usersList, setUsersList] = useState([]);

    const toast = useToast();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { usersViewList } = useSelector((state) => state.usersInfo);


    const user_id = DataID;



    function showToast(title, message, status) {
        toast({
            title: title,
            description: message,
            status: status,
            duration: 5000,
            isClosable: true,
            position: 'top',
        });
    }

    useEffect(() => {
        let isActive = true;  // Flag to manage cleanup and prevent setting state on unmounted component

        const fetchData = async () => {
            if (!user_id) return;  // Prevent fetch without a valid ID

            const TOKEN = localStorage.getItem('AccessToken');
            if (!TOKEN) {
                showToast("Authentication error", "No access token found", "error");
                if (isActive) setError("Authentication error: No access token found.");
                return;
            }

            try {
                const response = await dispatch(getUsersViewInfo({ user_id: user_id })).unwrap();
                if (isActive) {
                    // process response
                    setUsersList(response?.data);
                    console.log("Fetched Data for ID:", user_id, response);
                }
            } catch (error) {
                if (isActive) {
                    console.error("Error fetching data for ID:", user_id, error);
                    setError("Failed to fetch data");
                    showToast("Error", "Failed to fetch data", "error");
                }
            }
        };

        fetchData();

        return () => {
            isActive = false;  // Set flag to false on cleanup
        };
    }, [user_id, dispatch]);

    const navigate = useNavigate();
    const [toggle, setToggle] = useState(false);
    const { isOpen: isOpenCard, onOpen: onOpenCard, onClose: onCloseCard } = useDisclosure();
    const { isOpen: isOpenUser, onOpen: onOpenUser, onClose: onCloseUser } = useDisclosure();
    const { isOpen: isOpenActivity, onOpen: onOpenActivity, onClose: onCloseActivity } = useDisclosure();

    return (
        <div className="dropdown_container">
            <td>
                <button
                    className="reservation_action"
                    onClick={() => {
                        setToggle(!toggle);
                    }}
                >
                    <PiDotsThreeVertical className="three-dots" />
                </button>
                <div
                    className="userdropdown__content"
                    style={{ display: toggle ? 'block' : 'none' }}
                >
                    <p
                        className="dropdown__text users__text"
                        onClick={() => {
                            navigate(`/dashboard/useractivities/${user_id}`);
                        }
                        }
                    >View users activities</p>
                    <p className="dropdown__text users__text" onClick={onOpenCard}>Suspend user</p>
                    <p className="dropdown__text users__delete_text" onClick={onOpenUser}>Delete user account</p>
                    <p className="dropdown__text users__text" onClick={onOpenActivity}>Activate User</p>
                </div>
            </td>
            <SuspendUserModal isOpen={isOpenCard} onOpen={onOpenCard} onClose={onCloseCard} user_id={user_id} />
            <DeleteUserModal isOpen={isOpenUser} onOpen={onOpenUser} onClose={onCloseUser} user_id={user_id} />
            <ActivateUserModal isOpen={isOpenActivity} onOpen={onOpenActivity} onClose={onCloseActivity} user_id={user_id} />
        </div>
    );
}

export default UsersDropdown;
