// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// import firebase from "firebase";
// import "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBZHyjHeio4b85hyRmGYQpLQhBydmlzMHs",
  authDomain: "platterwise.firebaseapp.com",
  projectId: "platterwise",
  storageBucket: "platterwise.appspot.com",
  messagingSenderId: "487663652425",
  appId: "1:487663652425:web:3b610dd3967ab08cf2b998",
};

// Initialize Firebase
// firebase.initializeApp(firebaseConfig);
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const db = getFirestore(app);
