import React, { useEffect, useState } from 'react';
import { useDisclosure } from "@chakra-ui/react";
import CreateAddModal from '../Modals/CreateAddModal';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getAllAdsInfo } from "../../redux/advertInfo";
import AdvertPagination from '../../extraFunctions/AdvertPagination';
import LiveAdvertPagination from '../../extraFunctions/LiveAdvertPagination';

const Advertisement = () => {
    const { isOpen: isOpenAdd, onOpen: onOpenAdd, onClose: onCloseAdd } = useDisclosure();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { adverts } = useSelector((state) => state.advertInfo);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(3); // Set the number of items per page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = adverts?.slice(indexOfFirstItem, indexOfLastItem);

    const [liveCurrentPage, setLiveCurrentPage] = useState(1);
    const [liveItemsPerPage] = useState(3); // Set the number of items per page
    const indexOfLastAdItem = currentPage * itemsPerPage;
    const indexOfFirstAdItem = indexOfLastAdItem - itemsPerPage;

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const Paginate = (pageNumber) => setLiveCurrentPage(pageNumber);

    useEffect(() => {
        dispatch(getAllAdsInfo());
    }, [dispatch]);

    // Utility function to check if an advert is expired
    const isExpired = (toDate) => {
        const currentDate = new Date();
        const expiryDate = new Date(toDate);
        return currentDate > expiryDate;
    };

    // Filter out expired adverts
    const validAdverts = adverts?.filter(advert => !isExpired(advert?.to_duration));
    const currentAdItems = validAdverts?.slice(indexOfFirstAdItem, indexOfLastAdItem);

    console.log("adverts1", validAdverts);

    return (
        <div>
            <div className="advert__container">
                <div className="advert__content">
                    <p className="advert__text">Advertisement</p>

                    <div className="advert__create">
                        <button className="create__button" onClick={onOpenAdd}>Create Add</button>
                    </div>
                </div>

                <div className="advert__page">
                    <div className="live__campaigns">
                        <p className="campaigns__text">Live Campaigns</p>
                        <p className="campaigns__all"
                            onClick={() => navigate("/dashboard/livecampaigns")}
                        >see all</p>
                    </div>
                    <div className="ads__details">
                        {currentAdItems?.map((advert) => (
                            <div className="ads__content" key={advert?.id}>
                                <img src={advert?.img_url} className="advert__img" alt="advert__img" />
                                <p className="advert__img__text">{advert?.message}</p>
                            </div>
                        ))}
                    </div>

                    <LiveAdvertPagination
                        itemsPerPage={liveItemsPerPage}
                        totalItems={validAdverts?.length}
                        paginate={Paginate}
                        className="table_pagination"
                    />

                    <div className="live__campaigns all__campaigns">
                        <p className="campaigns__text">All Campaigns</p>
                        <p className="campaigns__all"
                            onClick={() => navigate("/dashboard/allcampaigns")}
                        >see all</p>
                    </div>

                    <div className="ads__details">
                        {currentItems?.map((advert) => (
                            <div className="ads__content" key={advert?.id}>
                                <img src={advert?.img_url} className="advert__img" alt="advert__img" />
                                <p className="advert__img__text">{advert?.message}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <AdvertPagination
                itemsPerPage={itemsPerPage}
                totalItems={adverts?.length}
                paginate={paginate}
                className="table_pagination"
            />
            <CreateAddModal isOpen={isOpenAdd} onOpen={onOpenAdd} onClose={onCloseAdd} />
        </div>
    );
}

export default Advertisement;
