import React from 'react';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Button } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend
);

const TopPerforming = ({ performanceData }) => {
    // console.log("performanceData", performanceData);

    const isReservationData = performanceData?.hasOwnProperty('total_reservation');
    const totalValue = isReservationData ? performanceData?.total_reservation : performanceData?.total_sales / 100;

    const data = {
        labels: isReservationData ? ['Reservations'] : ['Sales income'],
        datasets: [
            {
                label: 'Performance',
                data: [totalValue], // Directly use totalValue
                backgroundColor: ['#db4e2e'],
                borderColor: ['#db4e2e'],
                borderWidth: 1,
            },
        ],
    };
    const options = {
        responsive: true,
    };

    const backgroundCircle = {
        id: "backgroundCircle",
        beforeDatasetsDraw: (chart, args, pluginOptions) => {
            const { ctx } = chart;
            ctx.save();

            const xCoor = chart.getDatasetMeta(0).data[0].x;
            const yCoor = chart.getDatasetMeta(0).data[0].y;
            const innerRadius = chart.getDatasetMeta(0).data[0].innerRadius;
            const outerRadius = chart.getDatasetMeta(0).data[0].outerRadius;
            const width = outerRadius - innerRadius;
            const angle = Math.PI / 180;
            ctx.beginPath();
            ctx.lineWidth = width;
            ctx.strokeStyle = "#ffffff";
            ctx.arc(xCoor, yCoor, outerRadius - width / 2, 0, angle * 360, false);
            ctx.stroke();
        }
    };
    return (
        <div style={{ width: "270px", height: "270px", margin: "0 auto", position: "relative" }}>
            <Doughnut
                data={data}
                options={options}
                plugins={[backgroundCircle]}
            />
            <div className="doghnut__value">
                <p className="doughnut__text">Total {isReservationData ? 'Reservations' : 'Sales income'}</p>
                <p className="doughnut__text_i">{totalValue}</p>
            </div>
        </div>
    );
}

export default TopPerforming;
