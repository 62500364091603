import React from 'react';
import Chart from 'react-apexcharts';

class WeeklyChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                chart: {
                    type: 'bar',
                    height: 450,
                    border: '1px solid #fff',
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '30%',
                        columnbackgroundColor: 'red',
                        borderRadius: 8,
                        border: '1px solid #fff',
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    categories: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
                },
                fill: {
                    colors: ['#db4e2e'],
                },
            },
        };
    }

    render() {
        // Use the passed prop here
        const { monthlyReservation } = this.props;

        const chartData = [
            monthlyReservation?.first_week,
            monthlyReservation?.second_week,
            monthlyReservation?.third_week,
            monthlyReservation?.fourth_week
        ].map((value, index) => ({
            x: `Week ${index + 1}`,
            y: value
        }));

        return (
            <div id="chart">
                <Chart
                    options={this.state.options}
                    series={[{ name: 'Weekly Reservations', data: chartData }]}
                    type="bar"
                    height={300}
                />
            </div>
        );
    }
}

export default WeeklyChart;



// import React from 'react';
// import Chart from 'react-apexcharts';

// class WeeklyChart extends React.Component {
//     constructor(props) {
//         super(props);

//         this.state = {
//             options: {
//                 chart: {
//                     type: 'bar',
//                     height: 450,
//                     border: '1px solid #fff',
//                 },
//                 plotOptions: {
//                     bar: {
//                         horizontal: false,
//                         columnWidth: '30%',
//                         columnbackgroundColor: 'red',
//                         borderRadius: 8,
//                         border: '1px solid #fff',
//                     },
//                 },
//                 dataLabels: {
//                     enabled: false,
//                 },
//                 xaxis: {
//                     categories: ['01', '02', '03', '04',],
//                 },
//                 fill: {
//                     colors: ['#db4e2e'],
//                     height: 200,
//                 },
//             },
//             rawData: {
//                 1: 10,
//                 2: 20,
//                 3: 15,
//                 4: 25,
//             }
//         };
//     }

//     render() {
//         const chartData = Object.keys(this.state.rawData).map((day) => ({
//             x: day,
//             y: this.state.rawData[day]
//         }));

//         return (
//             <div id="chart">
//                 <Chart
//                     options={this.state.options}
//                     series={[{
//                         name: 'Weekly Reservations',
//                         data: chartData
//                     }]}
//                     type="bar"
//                     height={300}
//                 />
//             </div>
//         );
//     }
// }

// export default WeeklyChart;
