import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    useToast,
    ModalFooter,
    ModalBody,
    Button,
} from '@chakra-ui/react';
import { useDispatch } from "react-redux";
import { getUsersViewInfo, DeletePostInfo } from '../redux/usersInfo';



const RemovePost = ({ isOpen, onClose, user_id, post_id }) => {
    const toast = useToast();
    const dispatch = useDispatch();

    function showToast(title, message, status) {
        toast({
            title: message,
            status: status,
            duration: 5000,
            isClosable: true,
            position: 'top',
        });
    }


    return (
        <div>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                id="delete__modal"
                isCentered
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader className="delete__text">Are you sure you want to remove post?</ModalHeader>
                    <ModalFooter style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            mr={3}
                            className="delete__action"
                            onClick={async () => {
                                try {
                                    const response = await dispatch(DeletePostInfo({ user_id, post_id }));
                                    if (response?.payload) {
                                        // Optional: Check if response contains specific success message/data
                                        showToast("Success", "Post Deleted Successfully", "success");
                                        dispatch(getUsersViewInfo({ user_id }));  // Refresh user data if needed
                                        onClose();  // Close modal or perform other UI cleanup
                                    } else {
                                        throw new Error("No response payload");  // Handle cases where the response might not be as expected
                                    }
                                } catch (error) {
                                    console.error(error);
                                    showToast("Error", error.message || "Something went wrong", "error");
                                }
                            }}

                        >
                            Yes
                        </Button>
                        <Button
                            mr={3}
                            className="delete_action"
                            onClick={onClose}>
                            No
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </div>
    );
}

export default RemovePost;