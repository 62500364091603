import React, { useState } from 'react';
import { PiDotsThreeVertical } from "react-icons/pi";
import { useDisclosure } from "@chakra-ui/react";
import ReservationDetails from "../Components/Modals/ReservationDetails";

const Dropdown = ({ reservationData }) => {
    const [toggle, setToggle] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <div className="dropdown_container">
            <td>
                <button
                    className="reservation_action"
                    onClick={() => {
                        setToggle(!toggle);
                    }}
                >
                    <PiDotsThreeVertical className="three-dots" />
                </button>
                <div
                    className="dropdown__content"
                    style={{ display: toggle ? 'block' : 'none' }}
                >
                    <p className="dropdown__text" onClick={onOpen}>Reservation Details</p>
                </div>
                <ReservationDetails reservationData={reservationData} isOpen={isOpen} onClose={onClose} />
            </td>
        </div>
    );
}

export default Dropdown;
