import React, { useState, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import * as XLSX from 'xlsx';
import ArrowLeft from "../assets/icons/arrow-left.svg";
import CaretDown from "../assets/icons/CaretDown.svg";
import filter from "../assets/icons/filter.svg";
import download from "../assets/icons/download.svg";
import AllRestaurantPagination from '../../extraFunctions/AllRestaurantPagination';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getRestaurantInfo } from '../../redux/restaurantInfo';


const AllRestaurants = () => {

    const toast = useToast();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { restaurantData } = useSelector((state) => state.restaurantInfo);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(14); // Set the number of items per page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = restaurantData?.data?.slice(indexOfFirstItem, indexOfLastItem);
    const [selectedOption, setSelectedOption] = useState('');
    const [filterQuery, setFilterQuery] = useState('');

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const TOKEN = localStorage.getItem('AccessToken');

    function showToast(title, message, status) {
        toast({
            title: message,
            status: status,
            duration: 5000,
            isClosable: true,
            position: 'top',
        });
    }

    useEffect(() => {
        dispatch(getRestaurantInfo());
    }, [])

    const options = [
        { name: 'Restaurants name', id: 1, key: 'name' },
    ];

    const handleSelectedOption = (event) => {
        setSelectedOption(event.target.value);
        setFilterQuery(''); // Clear filter query on selection change
    };

    const filteredItems = currentItems?.filter(item => {
        const restaurantName = item.name.toLowerCase();
        return restaurantName.includes(filterQuery.toLowerCase());
    });

    const exportToExcel = () => {
        // Process each item to structure it for the Excel file
        const processedData = filteredItems.map(item => {

            return {
                "Restaurants Name": item?.name,
                'No of orders': item?.total_reservation,
                'No of cancelled orders': item?.total_cancelled_reservation,
                'Total Amount': item?.total_amount_paid / 100
            };
        });

        const ws = XLSX.utils.json_to_sheet(processedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Restaurants");
        XLSX.writeFile(wb, "restaurant.xlsx");
    };


    return (
        <div className="all__container">
            <div
                className="all__arrow"
                onClick={() => {
                    navigate(-1);
                }}
            >
                <img src={ArrowLeft} alt="arrow-left" className='arrow-left' />
            </div>

            <div className="all__content">
                <div className="all__text">
                    <p className="all__recent">Restaurants Activities</p>
                </div>
                <div className='all__fliter'>
                    <select className="top__restaurant select_filter" value={selectedOption} onChange={handleSelectedOption}>
                        <option value="">Filter</option>
                        {options.map((option) => (
                            <option key={option.id} value={option.name}>
                                {option.name}
                            </option>
                        ))}
                        <img src={filter} alt="filter-icon" className="filter__icon" />
                        <img src={CaretDown} alt="caret-down" className="top__caret" />
                    </select>

                    <input
                        type="text"
                        value={filterQuery}
                        onChange={(e) => setFilterQuery(e.target.value)}
                        placeholder="Type to filter..."
                        className="filter-input" // Add appropriate classes as needed
                    />

                    <div className="export__content" onClick={exportToExcel}>
                        <img src={download} alt="download-icon" className="download__icon" />
                        <p className="export-text">Export to Excel</p>
                    </div>
                </div>
            </div>
            <div className="restaurant__table all-rest">
                <table className='table__container_i'>

                    <tr>
                        <th className='table__header__text'>Restaurants Name</th>
                        <th className='table__header__text'>Number of orders</th>
                        <th className='table__header__text'>Number of cancelled orders</th>
                        <th className='table__header__text'>Total Amount</th>
                    </tr>

                    {
                        filteredItems?.map((data, index) => (
                            <tr className="reservation__table__rw" key={index}>
                                <td className="reservation__table__first">{data?.name}</td>
                                <td className="reservation__table__sec">{data?.total_reservation}</td>
                                <td className="reservation__table__sec">{data?.total_cancelled_reservation}</td>
                                <td className="reservation__table__sec">{`₦${(data?.total_amount_paid / 100).toLocaleString()}`}</td>
                            </tr>
                        ))
                    }
                </table>
                <AllRestaurantPagination
                    itemsPerPage={itemsPerPage}
                    totalItems={restaurantData?.data?.length}
                    paginate={paginate}
                    className="table_pagination"
                />
            </div>
        </div>
    );
}

export default AllRestaurants;
