import React, { useState } from 'react';
import {
    Text,
    VStack,
    Input,
    Stack,
    HStack,
    InputGroup,
    Button,
    Spacer,
    Flex,
    CardBody,
    Card,
    useToast,
    InputRightElement
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { validateEmail } from '../../Services/Api';
import { Link, useNavigate } from "react-router-dom";


const ForgotPassword = () => {
    const toast = useToast();
    const [email, setEmail] = useState('');
    const [isloading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();


    function showToast(title, message, status) {
        toast({
            title: message,
            status: status,
            duration: 5000,
            isClosable: true,
            position: 'top',
        });
    }

    const data = {
        email: email,
    };

    const handleChange = (e) => {
        setEmail(e.target.value)
    }

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        try {
            const res = await validateEmail(data).then((res) => {
                console.log("validateEmail", res)
                if (res?.data?.status_code === 200) {
                    showToast("Success", "Email Sent", "success");
                    navigate('/otp');
                } else if (res?.data?.status_code === 400) {
                    showToast("Error", "Email Does not Exist", "error");
                }
            })
        } catch (err) {
            console.log(err);
            setLoading(false);
            showToast("Error", "Email Does not Exist", "error");

        }
    }

    return (
        <div className="forgot__password__container">
            <VStack spacing={10}>
                <p className="forgot__password"> Forgot Password</p>
                <form>
                    <Card size="lg" width="40vw" id="forgot__card">
                        <CardBody>
                            <Stack spacing={5}>
                                <Spacer />

                                <p className="login__email forgot__email">Email</p>
                                <Input
                                    value={email}
                                    onChange={handleChange}
                                    size="lg"
                                    placeholder="Email"
                                    name="email"
                                />
                                <p className="otp__text">An OTP would be sent to reset your password</p>

                                <Spacer />
                                <Button
                                    isLoading={loading}
                                    loadingText="loading ..."
                                    onClick={handleSubmit}
                                    colorScheme="blue"
                                    variant="outline"
                                    rightIcon={<ArrowForwardIcon />}
                                    className="login__button"
                                >
                                    Send Mail
                                </Button>
                                <Flex>
                                    <Spacer />
                                </Flex>
                            </Stack>
                        </CardBody>
                    </Card>
                </form>
            </VStack>
        </div>
    );
}

export default ForgotPassword;

