import React from 'react';
import { Card, CardBody } from '@chakra-ui/react';
import Reservation from "../assets/icons/Reservation.svg";
import Restaurants from "../assets/icons/Restaurants.svg";
import TotalUsers from "../assets/icons/TotalUsers.svg";
import GreenUser from "../assets/icons/GreenUser.svg";
import up from "../assets/icons/up.svg";

const Cards = ({ usersData }) => {
    return (
        <div>
            <div className="cards__container">
                <div className="cards__holder">

                    <Card size="md" className="third_card">
                        <CardBody>
                            <div className="wallet__content">
                                <img src={TotalUsers} alt="wallet-icon" className="wallet__icon" />
                                <div className="wallet__income">
                                    <p className="wallet-text">Total Users</p>
                                    <p className="wallet-figure">{usersData?.total_users}</p>
                                </div>
                            </div>

                        </CardBody>
                    </Card>

                    <Card size="md" className="third_card">
                        <CardBody>
                            <div className="wallet__content">
                                <img src={GreenUser} alt="wallet-icon" className="wallet__icon" />
                                <div className="wallet__income">
                                    <p className="wallet-text">Active Users</p>
                                    <p className="wallet-figure">{usersData?.total_active_users}</p>
                                </div>
                            </div>

                        </CardBody>
                    </Card>

                    <Card size="md" className="third_card">
                        <CardBody>
                            <div className="wallet__content">
                                <img src={TotalUsers} alt="wallet-icon" className="wallet__icon" />
                                <div className="wallet__income">
                                    <p className="wallet-text">Inactive Users</p>
                                    <p className="wallet-figure">{usersData?.total_inactive_users}</p>
                                </div>
                            </div>

                        </CardBody>
                    </Card>

                    <Card size="md" className="third_card">
                        <CardBody>
                            <div className="wallet__content">
                                <img src={GreenUser} alt="wallet-icon" className="wallet__icon" />
                                <div className="wallet__income">
                                    <p className="wallet-text">Total number of posts</p>
                                    <p className="wallet-figure">{usersData?.total_posts}</p>
                                </div>
                            </div>
                            {/* <div className="wallet-increase">
                                <div className="wallet__increase">
                                    <img src={up} alt="wallet-increase" className="up__icon" />
                                </div>
                            </div> */}
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default Cards;
