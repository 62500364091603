import React, { useState, useEffect } from 'react';
import calendar from "../assets/icons/calendar.svg";
import { useToast } from '@chakra-ui/react';
import CaretDown from "../assets/icons/CaretDown.svg";
import RangeSlider from './RangeSlider';
import TopPerforming from '../../extraFunctions/TopPerforming';
import { getSalesInfo } from '../../redux/restaurantInfo';
import { useSelector, useDispatch } from 'react-redux';

const Analytics = ({ performanceData }) => {
    const toast = useToast();
    const dispatch = useDispatch();

    const [selectedOption, setSelectedOption] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const { salesRevenue } = useSelector((state) => state.restaurantInfo);

    function showToast(title, message, status) {
        toast({
            title: message,
            status: status,
            duration: 5000,
            isClosable: true,
            position: 'top',
        });
    }

    const options = [
        { name: 'Reservations', id: 1, key: 'top_performer' },
        { name: 'Sales income', id: 2, key: 'top_sales' },
    ];

    const Options = [
        { month: 'January', id: 1, key: 'january' },
        { month: 'February', id: 2, key: 'february' },
        { month: 'March', id: 3, key: 'march' },
        { month: 'April', id: 4, key: 'april' },
        { month: 'May', id: 5, key: 'may' },
        { month: 'June', id: 6, key: 'june' },
        { month: 'July', id: 7, key: 'july' },
        { month: 'August', id: 8, key: 'august' },
        { month: 'September', id: 9, key: 'september' },
        { month: 'October', id: 10, key: 'october' },
        { month: 'November', id: 11, key: 'november' },
        { month: 'December', id: 12, key: 'december' }
    ];

    // const data = {
    //     month: selectedMonth
    // }

    const handleSelectedOption = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleSelectedMonth = (event) => {
        const month = event.target.value;
        setSelectedMonth(month); // Update the state to the new month
    };

    // The useEffect hook will now have a condition to prevent running without a selected month.
    useEffect(() => {
        if (!selectedMonth) {
            // If no month is selected, don't run the fetchData function.
            return;
        }

        let isSubscribed = true;

        setLoading(true);
        setError(null);

        const TOKEN = localStorage.getItem('AccessToken');
        if (!TOKEN) {
            showToast("Authentication error", "No access token found", "error");
            setError("Authentication error: No access token found.");
            setLoading(false);
            return;
        }

        dispatch(getSalesInfo({ month: selectedMonth }))
            .unwrap()
            .then(response => {
                if (isSubscribed || response?.payload?.data?.status_code == 200) {
                    // Handle success here
                    showToast("Success", "Sales data fetched successfully", "success");
                }
            })
            .catch(error => {
                if (isSubscribed) {
                    setError("Failed to fetch restaurant data");
                    showToast("Error", "Failed to fetch restaurant data", "error");
                }
            })
            .finally(() => {
                if (isSubscribed) {
                    setLoading(false);
                }
            });

        // Cleanup function to prevent memory leaks
        return () => {
            isSubscribed = false;
        };
    }, [selectedMonth]); // This effect depends on selectedMonth only


    // console.log("salesRevenue", salesRevenue);

    // Determine which data to pass based on the selected option
    const selectedData = options.find(option => option.name === selectedOption)?.key;
    const dataToDisplay = performanceData[selectedData] || performanceData.top_performer;

    const maxValue = Math.max(salesRevenue?.first_week, salesRevenue?.second_week, salesRevenue?.third_week, salesRevenue?.fourth_week) / 100;




    return (
        <div>
            <div className="second__analytics">
                <div className="sales__container">
                    <div className="sales__content">
                        <div className="sales-text">
                            <p className="sales_text">Sales Revenue</p>
                        </div>
                        <select className="top__restaurant select_filter" value={selectedMonth} onChange={handleSelectedMonth}>
                            <option value="">Select Month</option>
                            {Options.map((option) => (
                                <option key={option.id} value={option.month}>
                                    {option.month}
                                </option>
                            ))}
                            <img src={CaretDown} alt="caret-down" className="top__caret" />
                        </select>
                    </div>

                    <div className="sales_slider_content">
                        <div className='sales-week'>
                            <p className="sales__week">Week 1</p>
                        </div>
                        <div className="sales_content_i">
                            <RangeSlider value={salesRevenue?.first_week / 100} max={maxValue} />
                            <p className="sales__amount">₦{salesRevenue?.first_week / 100}</p>
                        </div>

                    </div>
                    <div className="sales_slider_content">
                        <div className='sales-week'>
                            <p className="sales__week">Week 2</p>
                        </div>
                        <div className="sales_content_i">
                            <RangeSlider value={salesRevenue?.second_week / 100} max={maxValue} />
                            <p className="sales__amount">₦{salesRevenue?.second_week / 100}</p>
                        </div>

                    </div>

                    <div className="sales_slider_content">
                        <div className='sales-week'>
                            <p className="sales__week">Week 3</p>
                        </div>
                        <div className="sales_content_i">
                            <RangeSlider value={salesRevenue?.third_week / 100} max={maxValue} />
                            <p className="sales__amount">₦{salesRevenue?.third_week / 100}</p>
                        </div>

                    </div>

                    <div className="sales_slider_content">
                        <div className='sales-week'>
                            <p className="sales__week">Week 4</p>
                        </div>
                        <div className="sales_content_i">
                            <RangeSlider value={salesRevenue?.fourth_week / 100} max={maxValue} />
                            <p className="sales__amount">₦{salesRevenue?.fourth_week / 100}</p>
                        </div>

                    </div>

                </div>
                <div className="top__container">
                    <div className='top__content'>
                        <div className="top__text">
                            <p className="top__performing">Top Performing Restaurant</p>
                            <p className="top__performing_i">{performanceData?.top_performer?.name}</p>
                        </div>
                        <div>
                            <select className="top__restaurant" value={selectedOption} onChange={handleSelectedOption}>
                                <option value="">Select an option</option>
                                {options.map((option) => (
                                    <option key={option.id} value={option.name}>
                                        {option.name}
                                    </option>
                                ))}
                                <img src={CaretDown} alt="caret-down" className="top__caret" />
                            </select>
                        </div>
                    </div>
                    <TopPerforming performanceData={dataToDisplay} />
                </div>
            </div>

        </div>
    );
}

export default Analytics;
