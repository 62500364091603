import { configureStore } from "@reduxjs/toolkit";
import userInfo from "./userInfo";
import dashboardInfo from "./dashboardInfo";
import restaurantInfo from "./restaurantInfo";
import usersInfo from "./usersInfo";
import advertInfo from "./advertInfo";

export const store = configureStore({
  reducer: {
    userInfo,
    dashboardInfo,
    restaurantInfo,
    usersInfo,
    advertInfo,
  },
});
