import React, { useState, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import Header from "./Header";
import Cards from "./Cards";
import Example from './Analytics';
import ReservationTable from './ReservationTable';
import { useSelector, useDispatch } from 'react-redux';
import { getDashboardInfo } from '../../redux/dashboardInfo';

const Dashboard = () => {
    const toast = useToast();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const dashboardData = useSelector((state) => state.dashboardInfo.dashboardData);

    function showToast(title, message, status) {
        toast({
            title: title,
            description: message,
            status: status,
            duration: 5000,
            isClosable: true,
            position: 'top',
        });
    }

    useEffect(() => {
        const TOKEN = localStorage.getItem('AccessToken');
        if (!TOKEN) {
            showToast("Authentication error", "No access token found", "error");
            setError("Authentication error: No access token found.");
            setIsLoading(false);
            return;
        }

        // Make sure to properly handle the promise returned by the dispatch
        dispatch(getDashboardInfo(TOKEN)).then(response => {
            if (response?.payload?.data?.status_code == 200) {
                // Data fetched successfully
                setIsLoading(false);
            } else {
                // Handle failure
                setError(response.message || "Failed to fetch dashboard data");
                showToast("Error", response.message || "Failed to fetch dashboard data", "error");
                setIsLoading(false);
            }
        });
    }, [dispatch]);

    console.log("dashboardDataRedux", dashboardData);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <Header dashboardData={dashboardData} />
            <Cards dashboardData={dashboardData} />
            <Example dashboardData={dashboardData} />
            <ReservationTable dashboardData={dashboardData} />
        </div>
    );
};

export default Dashboard;
