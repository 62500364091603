import React from 'react';
import { Card, CardBody } from '@chakra-ui/react';
import Reservation from "../assets/icons/Reservation.svg";
import Restaurants from "../assets/icons/Restaurants.svg";
import TotalUsers from "../assets/icons/TotalUsers.svg";
import up from "../assets/icons/up.svg";

const Cards = ({ restaurantAnalysis }) => {
    return (
        <div>
            <div className="cards__container">
                <div className="cards__holder">

                    <Card size="md" className="second_card">
                        <CardBody>
                            <div className="wallet__content">
                                <img src={Reservation} alt="wallet-icon" className="wallet__icon" />
                                <div className="wallet__income">
                                    <p className="wallet-text">Total Reservation</p>
                                    <p className="wallet-figure">{restaurantAnalysis?.total_reservations}</p>
                                </div>
                            </div>

                        </CardBody>
                    </Card>

                    <Card size="md" className="second_card">
                        <CardBody>
                            <div className="wallet__content">
                                <img src={Restaurants} alt="wallet-icon" className="wallet__icon" />
                                <div className="wallet__income">
                                    <p className="wallet-text">Total Restaurants</p>
                                    <p className="wallet-figure">{restaurantAnalysis?.total_restaurants}</p>
                                </div>
                            </div>

                        </CardBody>
                    </Card>

                    <Card size="md" className="second_card">
                        <CardBody>
                            <div className="wallet__content">
                                <img src={TotalUsers} alt="wallet-icon" className="wallet__icon" />
                                <div className="wallet__income">
                                    <p className="wallet-text">Total Cancelled Reservations</p>
                                    <p className="wallet-figure">{restaurantAnalysis?.total_cancelled_reservations}</p>
                                </div>
                            </div>

                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default Cards;
