import React, { useState } from "react";

const AdvertPagination = ({ itemsPerPage, totalItems, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    const [currentPage, setCurrentPage] = useState(1);

    const handleClick = (number) => {
        setCurrentPage(number);
        paginate(number);
    };

    return (
        <nav>
            <ul className="pagination advert__pagination">
                {pageNumbers.map((number) => (
                    <li key={number} className="page-item" id="page__item">
                        <button onClick={() => handleClick(number)} className="page-link">
                            {number}
                        </button>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default AdvertPagination;


