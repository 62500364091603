import React, { useState, useEffect } from 'react';
import Header from '../Dashboard/Header';
import Cards from './Cards';
import UserTable from './UserTable';
import { useSelector, useDispatch } from 'react-redux';
import { getUsersInfo, getAllUsersInfo } from '../../redux/usersInfo';
import { useToast } from '@chakra-ui/react';

const Users = () => {
    const toast = useToast();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { usersData } = useSelector((state) => state.usersInfo);
    const { allUsers } = useSelector((state) => state.usersInfo);

    function showToast(title, message, status) {
        toast({
            title: title,
            description: message,
            status: status,
            duration: 5000,
            isClosable: true,
            position: 'top',
        });
    }

    // console.log("allUsers", allUsers);

    useEffect(() => {
        const TOKEN = localStorage.getItem('AccessToken');
        if (!TOKEN) {
            showToast("Authentication error", "No access token found", "error");
            setError("Authentication error: No access token found.");
            setIsLoading(false);
            return;
        }

        // Make sure to properly handle the promise returned by the dispatch
        dispatch(getAllUsersInfo(TOKEN)).then(response => {
            if (response?.payload?.data?.status_code == 200) {
                // Data fetched successfully
                setIsLoading(false);
            } else {
                // Handle failure
                setError(response.message || "Failed to fetch dashboard data");
                showToast("Error", response.message || "Failed to fetch dashboard data", "error");
                setIsLoading(false);
            }
        });

        dispatch(getUsersInfo(TOKEN)).then(response => {
            if (response?.payload?.data?.status_code == 200) {
                // Data fetched successfully
                setIsLoading(false);
            } else {
                // Handle failure
                setError(response.message || "Failed to fetch dashboard data");
                showToast("Error", response.message || "Failed to fetch dashboard data", "error");
                setIsLoading(false);
            }
        });
    }, [])


    return (
        <div>
            <Header usersData={usersData} />
            <Cards usersData={usersData} />
            <UserTable allUsers={allUsers} usersData={usersData} />
        </div>
    );
}

export default Users;
