import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    useToast,
    ModalFooter,
    ModalBody,
    Button,
} from '@chakra-ui/react';
import axios from "axios";
import { activateUser } from '../Services/Api';


const ActivityUserModal = ({ isOpen, onClose, user_id }) => {
    const toast = useToast();

    function showToast(title, message, status) {
        toast({
            title: message,
            status: status,
            duration: 5000,
            isClosable: true,
            position: 'top',
        });
    }

    const handleActivateUser = async (e) => {
        e.preventDefault();
        // Assuming activateUser returns an API endpoint or a configuration for axios
        try {
            const response = await axios.post(activateUser(user_id));
            if (response.status === 200) {
                showToast("Success", "User activated successfully", "success");
                onClose();
            } else {
                showToast("Error", "Failed to activate user", "error");
            }
        } catch (error) {
            console.error('Activation error:', error);
            showToast("Error", "Something went wrong", "error");
        }
    }

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                id="delete__modal"
                isCentered
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader className="delete__text">Are you sure you want to activate this user?</ModalHeader>
                    <ModalFooter style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            // colorScheme='green'
                            mr={3}
                            className="delete__action"
                            onClick={handleActivateUser}
                        >
                            Yes
                        </Button>
                        <Button
                            // colorScheme='red' 
                            mr={3}
                            className="delete_action"
                            onClick={onClose}>
                            No
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </div>
    );
}

export default ActivityUserModal;