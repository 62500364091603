import React, { useState } from 'react';
import CaretDown from "../assets/icons/CaretDown.svg"
import moment from 'moment';
import TablePagination from "../../extraFunctions/TablePagination";
import Dropdown from "../../extraFunctions/Dropdown";
import { useNavigate } from 'react-router-dom';


const ReservationTable = ({ dashboardData }) => {
    const navigate = useNavigate();

    const pendingStyle = {
        color: "#AD3307",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        width: "140px",
        height: "40px",
        borderRadius: "8px",
        background: "#FFECE5",
        fontWeight: "600",
        fontSize: "14px",
        fontFamily: "Poppins"
    }

    const approvedStyle = {
        color: "#0F973D",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        width: "140px",
        height: "40px",
        borderRadius: "8px",
        background: "#E7F6EC",
        fontWeight: "600",
        fontSize: "14px",
        fontFamily: "Poppins"
    }

    const rejectedStyle = {
        color: "#D42620",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        width: "140px",
        height: "40px",
        borderRadius: "8px",
        background: "#FBEAE9",
        fontWeight: "600",
        fontSize: "14px",
        fontFamily: "Poppins"
    }

    const successStyle = {
        color: "#0F973D",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        width: "140px",
        height: "40px",
        borderRadius: "8px",
        background: "#E7F6EC",
        fontWeight: "600",
        fontSize: "14px",
        fontFamily: "Poppins"
    }

    const inprogressStyle = {
        color: "#AD3307",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        width: "140px",
        height: "40px",
        borderRadius: "8px",
        background: "#FFECE5",
        fontWeight: "600",
        fontSize: "14px",
        fontFamily: "Poppins"
    }

    const BillStyle = {
        color: "black",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        width: "140px",
        height: "40px",
        borderRadius: "8px",
        background: "#FFECE5",
        fontWeight: "600",
        fontSize: "14px",
        fontFamily: "Poppins"
    }

    const processingStyle = {
        color: "cyan",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        width: "140px",
        height: "40px",
        borderRadius: "8px",
        background: "red",
        fontWeight: "600",
        fontSize: "14px",
        fontFamily: "Poppins"
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5); // Set the number of items per page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = dashboardData?.recent_reservation?.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="reservation__table__container">
            <div className="reservation__table">
                <div className="reservation__content">
                    <div className="reservation__text">
                        <p className="reservation__activity">Recent Reservation Activities</p>
                    </div>
                    <div className="reservation__calendar">
                        <p className="calendar__text">February</p>
                        <img src={CaretDown} alt="caret_down" className="reservation-caret" />
                    </div>
                </div>
                <table className='table__container'>
                    <thead>
                        <tr>
                            <th className='table__header__text'>NO</th>
                            <th className='table__header__text'>Customers Names</th>
                            <th className='table__header__text'>Reservation ID</th>
                            <th className='table__header__text'>Date</th>
                            <th className='table__header__text'>Restaurant's Name</th>
                            <th className='table__header__text'>Location</th>
                            <th className='table__header__text'>Reservation Status</th>
                            <th className='table__header__text'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems?.map((data, index) => {
                            // Parse the JSON string representing the all_guests array into an actual array of objects
                            const allGuests = JSON.parse(data.all_guests);

                            // Access the first name property of the first guest
                            const customerName = allGuests.length > 0 ? allGuests[0].name.split(' ')[0] : ''; // Get the first name
                            return (
                                <tr className="reservation__table__rw" key={data?.id}>
                                    <td className="reservation__table__first">{index + 1}</td>
                                    <td className="reservation__table__sec">{customerName}</td> {/* Updated this line */}
                                    <td className="reservation__table__sec">{data?.id}</td>
                                    <td className="reservation__table__sec">{moment(data?.reservation_date).format('YYYY-MM-DD')}</td>
                                    <td className="reservation__table__sec">{data?.restaurant?.name}</td>
                                    <td className="reservation__table__sec">{data?.restaurant?.address}</td>
                                    <td>

                                        {data.status === 1 && <span style={pendingStyle}>Pending</span>}
                                        {data.status === 2 && <span style={approvedStyle}>Approved</span>}
                                        {data.status === 0 && <span style={rejectedStyle}>Rejected</span>}
                                        {data.status === 4 && <span style={successStyle}>Completed</span>}
                                        {data.status === 3 && <span style={inprogressStyle}>In Progress</span>}
                                        {data.status === 5 && <span style={BillStyle}>Bill Generated</span>}
                                        {data.reservation_status === "split_bill" && <span style={processingStyle}>Accepted</span>}
                                    </td>

                                    <Dropdown reservationData={data} />
                                </tr>
                            );
                        })}
                    </tbody>
                </table>


                <p
                    className="view__all"
                    onClick={() => {
                        navigate("/dashboard/allreservations")
                    }}
                >View all</p>
                <TablePagination
                    itemsPerPage={itemsPerPage}
                    totalItems={dashboardData?.recent_reservation?.length}
                    paginate={paginate}
                    className="table_pagination"
                />

            </div>
        </div>
    );
}

export default ReservationTable;
