import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import {
  getUsersData,
  getAllUsers,
  getUserViewList,
  getPosts,
  DeletePost,
  getReservationDetails,
  DeleteUser,
} from "../Services/Api";
import axios from "axios";

const initialState = {
  isLoading: false,
  loading: false,
  usersLoading: false,
  postsLoading: false,
  getLoading: false,
  detailsLoading: false,
  userLoading: false,
  error: false,
  usersData: [],
  allUsers: [],
  usersViewList: [],
  Posts: [],
  reservationDetails: [],
};

const TOKEN = localStorage.getItem("AccessToken");

export const getUsersInfo = createAsyncThunk(
  "users/getUsersInfo",
  async (TOKEN, thunkAPI) => {
    try {
      const headers = {
        Authorization: `Bearer ${TOKEN}`,
      };

      const res = await getUsersData(headers);
      if (res.error) {
        // Assuming your API response contains an error field in case of failure
        throw new Error(res.error);
      }
      return res;
    } catch (err) {
      return thunkAPI.rejectWithValue(
        err.message || "Failed to fetch users data"
      );
    }
  }
);

export const getAllUsersInfo = createAsyncThunk(
  "users/getAllUsersInfo",
  async (TOKEN, thunkAPI) => {
    try {
      const headers = {
        Authorization: `Bearer ${TOKEN}`,
      };

      const res = await getAllUsers(headers);
      if (res.error) {
        // Assuming your API response contains an error field in case of failure
        throw new Error(res.error);
      }
      return res;
    } catch (err) {
      return thunkAPI.rejectWithValue(
        err.message || "Failed to fetch users data"
      );
    }
  }
);

export const getUsersViewInfo = createAsyncThunk(
  "users/getUsersViewInfo",
  async (data, thunkAPI) => {
    try {
      const TOKEN = localStorage.getItem("AccessToken"); // Fetch token inside the thunk function
      const headers = {
        Authorization: `Bearer ${TOKEN}`,
      };

      const res = await getUserViewList(data, headers);
      if (res.error) {
        throw new Error(res.error);
      }
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(
        err.response?.data || "Failed to fetch user data"
      );
    }
  }
);

export const getUsersPostsInfo = createAsyncThunk(
  "users/getUsersPostsInfo",
  async (userId, thunkAPI) => {
    try {
      const TOKEN = localStorage.getItem("AccessToken"); // Fetch the token securely
      if (!TOKEN) throw new Error("No access token available.");

      const headers = {
        Authorization: `Bearer ${TOKEN}`,
      };

      // Modify the URL to include the userId
      const response = await axios.get(
        `https://api2.platterwise.com/admin-api/user-list/user-post-activities/${userId}`,
        { headers }
      );
      return response; // Assuming the response has a 'data' field with the info you want
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.message || "Failed to fetch user posts"
      );
    }
  }
);

export const getReservationDetailsInfo = createAsyncThunk(
  "users/getReservationDetailsInfo",
  async (userId, thunkAPI) => {
    try {
      const TOKEN = localStorage.getItem("AccessToken"); // Fetch the token securely
      if (!TOKEN) throw new Error("No access token available.");

      const headers = {
        Authorization: `Bearer ${TOKEN}`,
      };

      // Modify the URL to include the userId
      const response = await axios.get(
        `https://api2.platterwise.com/admin-api/user-list/user-reservation-activities/${userId}`,
        { headers }
      );
      return response; // Assuming the response has a 'data' field with the info you want
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.message || "Failed to fetch user posts"
      );
    }
  }
);

export const DeletePostInfo = createAsyncThunk(
  "users/deletePostInfo",
  async (data, thunkAPI) => {
    try {
      const TOKEN = localStorage.getItem("AccessToken");
      const headers = {
        Authorization: `Bearer ${TOKEN}`,
        "Content-Type": "application/json",
      };

      const res = await DeletePost(data, headers);
      console.log("res", res);
      // Assuming DeletePost returns the response directly you might need to adjust based on actual response structure
      return { user_id: data.user_id, post_id: data.post_id };
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.error ||
          error.message ||
          "Failed to fetch user posts"
      );
    }
  }
);

export const DeleteUserInfo = createAsyncThunk(
  "users/deleteUserInfo",
  async (data, thunkAPI) => {
    try {
      const TOKEN = localStorage.getItem("AccessToken");
      const headers = {
        Authorization: `Bearer ${TOKEN}`,
        "Content-Type": "application/json",
      };

      const res = await DeleteUser(data, headers);
      console.log("res3", res);
      // Assuming DeletePost returns the response directly you might need to adjust based on actual response structure
      return { user_id: data?.user_id };
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.error ||
          error.message ||
          "Failed to fetch user posts"
      );
    }
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsersInfo.pending, (state) => {
        state.isLoading = true;
        state.error = false;
      })
      .addCase(getUsersInfo.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.usersData = payload?.data?.data;
        state.error = false;
        // console.log(payload);
      })
      .addCase(getUsersInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Something went wrong";
      })

      .addCase(getAllUsersInfo.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getAllUsersInfo.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allUsers = payload?.data?.data;
        state.error = false;
        // console.log(payload);
      })
      .addCase(getAllUsersInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Something went wrong";
      })

      .addCase(getUsersViewInfo.pending, (state) => {
        state.usersLoading = true;
        state.error = false;
      })

      .addCase(getUsersViewInfo.fulfilled, (state, { payload }) => {
        // console.log("Payload received:", payload);
        state.usersLoading = false;
        state.usersViewList = payload?.data;
      })

      .addCase(getUsersViewInfo.rejected, (state, action) => {
        state.usersLoading = false;
        state.error = action.payload || "Something went wrong";
      })

      .addCase(getUsersPostsInfo.pending, (state) => {
        state.postsLoading = true;
        state.error = false;
      })

      .addCase(getUsersPostsInfo.fulfilled, (state, { payload }) => {
        state.postsLoading = false;
        state.Posts = payload?.data?.data;
        state.error = false;
      })

      .addCase(getUsersPostsInfo.rejected, (state, action) => {
        state.postsLoading = false;
        state.error = action.payload || "Something went wrong";
      })

      .addCase(DeletePostInfo.pending, (state) => {
        state.getLoading = true;
        state.error = null;
      })

      .addCase(DeletePostInfo.fulfilled, (state, action) => {
        const { post_id, user_id } = action.meta.arg;

        if (Array.isArray(state.Posts.data)) {
          state.Posts.data = state.Posts.data.filter(
            (post) => post.id !== post_id && post.user_id !== user_id
          );
        } else {
          console.error("Posts.data is not an array:", state.Posts);
        }

        state.getLoading = false;
      })

      .addCase(DeletePostInfo.rejected, (state, action) => {
        state.getLoading = false;
        state.error = action.payload || "Something went wrong";
      })

      .addCase(getReservationDetailsInfo.pending, (state) => {
        state.detailsLoading = true;
        state.error = false;
      })

      .addCase(getReservationDetailsInfo.fulfilled, (state, { payload }) => {
        state.detailsLoading = false;
        state.reservationDetails = payload?.data?.data;
        state.error = false;
        console.log("reservationDetails", payload);
      })

      .addCase(getReservationDetailsInfo.rejected, (state, action) => {
        state.detailsLoading = false;
        state.error = action.payload || "Something went wrong";
      })

      .addCase(DeleteUserInfo.pending, (state) => {
        state.userLoading = true;
        state.error = null;
      })

      .addCase(DeleteUserInfo.fulfilled, (state, action) => {
        const { user_id } = action.meta.arg;

        if (Array.isArray(state.allUsers)) {
          state.allUsers = state.allUsers.filter((user) => user.id !== user_id);
        } else {
          console.error("allUsers is not an array:", state.allUsers);
        }

        state.userLoading = false;
      })

      .addCase(DeleteUserInfo.rejected, (state, action) => {
        state.userLoading = false;
        state.error = action.payload || "Something went wrong";
      });
  },
});

export default usersSlice.reducer;
