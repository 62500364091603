import React, { useState } from 'react';
import CaretDown from "../assets/icons/CaretDown.svg"
import { useNavigate } from 'react-router-dom';
import RestaurantPagination from '../../extraFunctions/RestaurantPagination';

const RestaurantTable = ({ restaurantData }) => {
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5); // Set the number of items per page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentRestaurant = restaurantData?.data?.slice(indexOfFirstItem, indexOfLastItem);

    // console.log("current", currentRestaurant);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="reservation__table__container">
            <div className="restaurant__table">
                <div className="reservation__content">
                    <div className="reservation__text">
                        <p className="reservation__activity">Restaurant's Activities</p>
                    </div>
                    <div className="reservation__calendar">
                        <p className="calendar__text">February</p>
                        <img src={CaretDown} alt="caret_down" className="reservation-caret" />
                    </div>
                </div>

                <table className='table__container_i'>

                    <tr>
                        <th className='table__header__text'>Restaurants Name</th>
                        <th className='table__header__text'>Number of orders</th>
                        <th className='table__header__text'>Number of cancelled orders</th>
                        <th className='table__header__text'>Total Amount</th>
                    </tr>
                    {
                        currentRestaurant?.map((data, index) => (
                            <tr className="reservation__table__rw" key={index}>
                                <td className="reservation__table__first">{data?.name}</td>
                                <td className="reservation__table__sec">{data?.total_reservation}</td>
                                <td className="reservation__table__sec">{data?.total_cancelled_reservation}</td>
                                <td className="reservation__table__sec">{`₦${(data?.total_amount_paid / 100).toLocaleString()}`}</td>
                            </tr>
                        ))
                    }

                </table>
                <p
                    className="view__all_rest"
                    onClick={() => {
                        navigate("/dashboard/allrestaurants")
                    }}
                >View all</p>

                <RestaurantPagination
                    itemsPerPage={itemsPerPage}
                    totalItems={restaurantData?.data?.length}
                    paginate={paginate}
                    className="table_pagination"
                />

            </div>
        </div>
    );
}

export default RestaurantTable;
