import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { signup, login } from "../Services/Api";

const initialState = {
  signupLoading: false,
  loginLoading: false,
  error: false,
  user: [],
};

export const signupUser = createAsyncThunk("user/signupUser", async (data) => {
  try {
    const res = await signup(data);
    return res;
  } catch (err) {
    return err;
  }
});

export const loginUser = createAsyncThunk("user/loginUser", async (data) => {
  try {
    const res = await login(data);
    return res;
  } catch (err) {
    return err;
  }
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signupUser.pending, (state) => {
        state.signupLoading = true;
      })
      .addCase(signupUser.fulfilled, (state, { payload }) => {
        state.signupLoading = false;
        state.user = payload?.data;
        // console.log(payload);
      })
      .addCase(signupUser.rejected, (state) => {
        state.signupLoading = false;
        state.error = true;
      })
      .addCase(loginUser.pending, (state) => {
        state.loginLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.loginLoading = false;
        state.user = payload;
        // console.log(payload);
      })
      .addCase(loginUser.rejected, (state) => {
        state.loginLoading = false;
        state.error = true;
      });
  },
});

export default userSlice.reducer;
