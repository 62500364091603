import React, { useState, useEffect } from 'react';
import ArrowLeft from "../assets/icons/arrow-left.svg";
import BoxProfile from "../assets/icons/BoxProfile.svg";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import CaretDown from "../assets/icons/CaretDown.svg";
import ActivityDropdown from "../../extraFunctions/ActivityDropdown";
import BoxDropdown from '../../extraFunctions/BoxDropdown';
import { useToast } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { getUsersViewInfo, getUsersPostsInfo } from "../../redux/usersInfo";
import moment from "moment";
import AllPostsPagination from '../../extraFunctions/AllPostsPagination';

const UserActivities = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    let { user_id } = useParams();

    console.log("user_id", user_id);

    const toast = useToast();

    const [activeTab, setActiveTab] = useState('active');
    const [activePane, setActivePane] = useState('active')
    const [selectedMonth, setSelectedMonth] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const usersViewList = useSelector(state => state.usersInfo.usersViewList);
    const { Posts } = useSelector((state) => state.usersInfo);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(4); // Set the number of items per page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = Posts?.data?.slice(indexOfFirstItem, indexOfLastItem)

    const paginate = (pageNumber) => setCurrentPage(pageNumber);


    function showToast(title, message, status) {
        toast({
            title: title,
            description: message,
            status: status,
            duration: 5000,
            isClosable: true,
            position: 'top',
        });
    }

    const Options = [
        { month: 'January', id: 1, key: 'january' },
        { month: 'February', id: 2, key: 'february' },
        { month: 'March', id: 3, key: 'march' },
        { month: 'April', id: 4, key: 'april' },
        { month: 'May', id: 5, key: 'may' },
        { month: 'June', id: 6, key: 'june' },
        { month: 'July', id: 7, key: 'july' },
        { month: 'August', id: 8, key: 'august' },
        { month: 'September', id: 9, key: 'september' },
        { month: 'October', id: 10, key: 'october' },
        { month: 'November', id: 11, key: 'november' },
        { month: 'December', id: 12, key: 'december' }
    ];

    const handleSelectedMonth = (event) => {
        const month = event.target.value;
        setSelectedMonth(month); // Update the state to the new month
    };

    useEffect(() => {
        // Update the activeTab state based on the current path
        const path = location.pathname;
        if (path.includes(`/dashboard/useractivities/${user_id}`)) {
            setActiveTab('active');
        } else if (path.includes(`/dashboard/reservationactivities/${user_id}`)) {
            setActiveTab('inactive');
        }
    }, [location, user_id]); // Run the effect on location and user_id changes


    useEffect(() => {
        if (user_id) {
            dispatch(getUsersViewInfo({ user_id: user_id }));
            dispatch(getUsersPostsInfo(user_id)); // Ensure this matches the thunk signature
        }
    }, [user_id, dispatch]);


    return (
        <div>
            <div className="activities__container">
                <div className="arrow__back">
                    <img src={ArrowLeft} className="arrow-left" alt="back-icon" onClick={() => {
                        navigate(-1)
                    }} />
                    <p className="activities__text">User Activities</p>
                </div>

                <div className="activities__box">
                    <div className="box__content">
                        <div className="box__profile">
                            {
                                usersViewList?.profile?.profileUrl ?
                                    <img src={usersViewList.profile.profileUrl} className="box-profile first__image" alt="profile-img" /> :
                                    <img src={BoxProfile} className="box-profile first__image" alt="default-profile-img" />
                            }

                            <div className="box__name">
                                <p className="box-name">{usersViewList?.profile?.full_name}</p>
                                <p className="box-at">{usersViewList?.profile?.username}</p>
                            </div>
                        </div>
                        <div className="box__account">
                            <div className="box__created">
                                <p className="box-account">Account created:</p>
                                {/* <p className="box-account">Total time spent:</p> */}
                            </div>
                            <div className="account__values">
                                <p className="box-values box">{moment(usersViewList?.profile?.created_at).format('MMMM Do YYYY')}</p>
                                {/* <p className="box-values box_i">100 hours</p> */}
                            </div>
                        </div>
                        <div className="box__dots">
                            <BoxDropdown user_id={user_id} />
                        </div>
                    </div>
                </div>
                <div className="activity__indicator">
                    <button
                        className={`tab_i ${activeTab === 'active' ? 'active' : ''}`}
                        onClick={() => {
                            navigate(`/dashboard/useractivities/${user_id}`);
                        }}
                    >Community Activities
                    </button>
                    <button
                        className={`tab_i ${activeTab === 'inactive' ? 'active' : ''}`}
                        onClick={() => {
                            navigate(`/dashboard/reservationactivities/${user_id}`);
                        }}
                    >
                        Reservation Activities
                    </button>
                </div>

                <div className="box__followers">
                    <div className="boxes">
                        <p className="box__text"> {usersViewList?.profile?.followers} <span className="box-span-text">Followers</span></p>
                    </div>

                    <div className="boxes">
                        <p className="box__text"> {usersViewList?.profile?.following} <span className="box-span-text">Followings</span></p>
                    </div>

                    <div className="boxes">
                        <p className="box__text"> {usersViewList?.profile?.total_posts} <span className="box-span-text">Posts</span></p>
                    </div>
                </div>

                <div className="activity__details">
                    <div className="activity__post">
                        <button
                            className={`pane ${activePane === 'active' ? 'active' : ''}`}
                            onClick={() => setActivePane('active')}
                        >Posts
                        </button>
                        <button
                            className={`pane ${activePane === 'inactive' ? 'active' : ''}`}
                            onClick={() => setActivePane('inactive')}>
                            Top comments
                        </button>
                    </div>

                    {/* <div className="activity__filter">
                        <select className="top__restaurant select_filter" value={selectedMonth} onChange={handleSelectedMonth}>
                            <option value="">Select Month</option>
                            {Options.map((option) => (
                                <option key={option.id} value={option.month}>
                                    {option.month}
                                </option>
                            ))}
                            <img src={CaretDown} alt="caret-down" className="top__caret" />
                        </select>
                    </div> */}
                </div>

                <div>
                    {
                        currentItems?.length > 0 ? (
                            currentItems?.map((post) => (
                                <div className="activity__activities" key={post?.id}>
                                    <div className="activity__first">
                                        {
                                            post?.user?.profileUrl ?
                                                <img src={post?.user?.profileUrl} className="box-profile" alt="profile-img" /> :
                                                <img src={BoxProfile} className="box-profile first__image" alt="default-profile-img" />
                                        }
                                        {/* <img src={post?.user?.profileUrl} className="box-profile" alt="profile-img" /> */}
                                        <div className="activity__first__details">
                                            <div className="activity__first__name">
                                                <p className="activity__first__text">{post?.user?.full_name}</p>
                                                <p className="activity__first__text brook">{post?.user?.username}</p>
                                            </div>
                                            <div className="activity__first__details">
                                                <p className="activity__first__details__text">{post?.content_post}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="activity__second">
                                        <div className="activity__second__view  views">
                                            <p className="view__text">Views</p>
                                            <p className="view__text">{post?.total_views}</p>
                                        </div>
                                        <div className="activity__second__view comments">
                                            <p className="view__text">Comments</p>
                                            <p className="view__text">{post?.total_comments}</p>
                                        </div>
                                        <div className="box__dots">
                                            <ActivityDropdown user_id={user_id} post_id={post?.id} />
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="activity__activities">
                                <div className="activity__first">
                                    <img src={BoxProfile} className="box-profile" alt="profile-img" />
                                    <div className="activity__first__details">
                                        <div className="activity__first__name">
                                            <p className="activity__first__text no__post">No Posts Available</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <AllPostsPagination
                    itemsPerPage={itemsPerPage}
                    totalItems={Posts?.data?.length}
                    paginate={paginate}
                    className="table_pagination"
                />
            </div>
        </div>
    );
}

export default UserActivities;
