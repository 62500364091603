import React, { useState } from 'react';
import { PiDotsThreeVertical } from "react-icons/pi";
import { useDisclosure } from "@chakra-ui/react";
import SuspendUserModal from './SuspendUserModal';
import DeleteUserModal from './DeleteUserModal';

const BoxDropdown = ({ user_id }) => {
    const [toggle, setToggle] = useState(false);
    const { isOpen: isOpenCard, onOpen: onOpenCard, onClose: onCloseCard } = useDisclosure();
    const { isOpen: isOpenUser, onOpen: onOpenUser, onClose: onCloseUser } = useDisclosure();
    return (
        <div className="dropdown__container">
            <button
                className="reservation_action"
                onClick={() => {
                    setToggle(!toggle);
                }}
            >
                <PiDotsThreeVertical className="three-dots" />
            </button>
            <div
                className="userdropdown__content activity__dropdown"
                style={{ display: toggle ? 'block' : 'none' }}
            >
                <p
                    className="dropdown__text users__text"
                    onClick={onOpenCard}
                >Suspend User</p>
                <p className="dropdown__text users__text" onClick={onOpenUser}>Delete user account</p>
            </div>
            <SuspendUserModal isOpen={isOpenCard} onOpen={onOpenCard} onClose={onCloseCard} user_id={user_id} />
            <DeleteUserModal isOpen={isOpenUser} onOpen={onOpenUser} onClose={onCloseUser} user_id={user_id} />
        </div>
    );
}

export default BoxDropdown;
