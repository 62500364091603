import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    useToast,
    ModalFooter,
    ModalBody,
    Button,

} from '@chakra-ui/react';
import { useDispatch } from "react-redux";
import { getUsersInfo, DeleteUserInfo } from '../redux/usersInfo';


const DeleteUserModal = ({ isOpen, onClose, user_id }) => {
    const toast = useToast();
    const dispatch = useDispatch();

    function showToast(title, message, status) {
        toast({
            title: message,
            status: status,
            duration: 5000,
            isClosable: true,
            position: 'top',
        });
    }

    // console.log("user_id3", user_id);


    return (
        <div>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                id="delete__modal"
                isCentered
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader className="delete__text">Are you sure you want to delete this user?</ModalHeader>
                    <ModalFooter style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            // colorScheme='green'
                            mr={3}
                            className="delete__action"
                            onClick={async () => {
                                const TOKEN = localStorage.getItem('AccessToken');
                                try {
                                    const response = await dispatch(DeleteUserInfo({ user_id }));
                                    // console.log("response3", response);
                                    if (response?.payload) {
                                        showToast("Success", "UserDeleted Successfully", "success");
                                        dispatch(getUsersInfo(TOKEN));
                                        onClose();
                                    }
                                    else {
                                        throw new Error("No response payload");  // Handle cases where the response might not be as expected
                                    }
                                } catch (error) {
                                    console.log(error);
                                    showToast("Error", error.message || "Something went wrong", "error");
                                }
                            }}
                        >
                            Yes
                        </Button>
                        <Button
                            // colorScheme='red' 
                            mr={3}
                            className="delete_action"
                            onClick={onClose}>
                            No
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </div>
    );
}

export default DeleteUserModal;