import React, { useState } from 'react';
import { Button, useToast, Input } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Background from "../assets/icons/Background.svg";
import { FiEye } from "react-icons/fi";
import { FaRegEyeSlash } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginUser } from "../../redux/userInfo";

const Login = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [isloading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    function showToast(title, message, status) {
        toast({
            title: message,
            status: status,
            duration: 5000,
            isClosable: true,
            position: 'top',
        });
    }

    const data = {
        email: email,
        password: password,
    };

    const handleClick = () => setShow(!show);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'email') {
            setEmail(value);
        } else if (name === 'password') {
            setPassword(value);
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            dispatch(loginUser(data)).then((response) => {
                console.log(response);

                if (response?.payload?.data?.status_code === 200) {
                    setIsLoading(false);
                    localStorage.setItem('AccessToken', response?.payload?.data?.token?.original?.access_token);
                    showToast('Success', 'Login Successful', 'success');
                    navigate('/');
                    window.location.reload();
                }
                else if (response?.payload?.data?.status_code === 401) {
                    setIsLoading(false);
                    const errorData = response?.payload?.data?.response;
                    if (errorData) {
                        showToast('Error', errorData, 'error');
                    } else {
                        showToast('Error', 'An error occurred', 'error');
                    }
                    setIsLoading(false);
                }
            });
        } catch (error) {
            // console.log(error);
            if (error.response) {
                if (error.response.status === 400) {
                    showToast('Error', 'Invalid Credentials', 'error');
                    setIsLoading(false);
                } else if (error.response.status === 500) {
                    showToast('Error', 'Server Error', 'error');
                    setIsLoading(false);
                }
            }
        }
    };

    return (
        <div>
            <div className="auth__container">
                <div className="auth__content">
                    <div className="signin-text">
                        <p className="signin-admin">Welcome, Admin</p>
                    </div>
                    <div className="login__form">
                        <p className="text__name">Email</p>
                        <Input
                            type="text"
                            placeholder='Enter your email'
                            id="login__input"
                            name="email"
                            onChange={handleChange}
                        />

                        <p className="text__name">Password</p>
                        <Input
                            type={show ? 'text' : 'password'}
                            placeholder='Enter your password'
                            id="login__input"
                            className="password"
                            name="password"
                            onChange={handleChange}
                        />
                        {show ? <FaRegEyeSlash className="password__icon" onClick={handleClick} /> : <FiEye className="password__icon" onClick={handleClick} />}
                        <div className='forgot-pass'>
                            <p className="forgot__text"><Link to={"/forgotpassword"}>Forgot Password ?</Link></p>
                        </div>

                        <Button
                            id='auth_button'
                            isLoading={isloading}
                            loadingText="Verifying your credentials ..."
                            onClick={handleLogin}
                        >
                            Sign in
                        </Button>
                    </div>

                    <div className="login_account">
                        <p className="login__account__text">
                            Don't have an account? <Link to={"/signup"}><span className="login__span">Sign up</span></Link>
                        </p>

                    </div>
                </div>

                <div className="auth__image">
                    <img src={Background} alt="background-img" className="background-img" />
                </div>

            </div>
        </div>
    );
}

export default Login;
