import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { useSelector, useDispatch } from 'react-redux';
import { getAllUsersInfo } from '../../redux/usersInfo';
import UsersPagination from '../../extraFunctions/UsersPagination';
import UsersDropdown from '../../extraFunctions/UsersDropdown';
import ArrowLeft from "../assets/icons/arrow-left.svg";
import CaretDown from "../assets/icons/CaretDown.svg"
import filter from "../assets/icons/filter.svg";
import download from "../assets/icons/download.svg";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { getUsersViewInfo, getUsersPostsInfo } from "../../redux/usersInfo";

const AllUsers = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let { user_id } = useParams();

    const { allUsers } = useSelector((state) => state.usersInfo);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(9); // Set the number of items per page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = allUsers?.data?.slice(indexOfFirstItem, indexOfLastItem);
    const [selectedOption, setSelectedOption] = useState('');
    const [filterQuery, setFilterQuery] = useState('');
    const usersViewList = useSelector(state => state.usersInfo.usersViewList);
    const { Posts } = useSelector((state) => state.usersInfo);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        dispatch(getAllUsersInfo());
    }, [])

    useEffect(() => {
        if (user_id) {
            dispatch(getUsersViewInfo({ user_id }));
            dispatch(getUsersPostsInfo(user_id)); // Ensure this matches the thunk signature
        }
    }, [user_id, dispatch]);

    console.log("posts", Posts);

    const options = [
        { name: 'Fullname', id: 1, key: 'full_name' },
        { name: 'Username', id: 2, key: 'username' },
        { name: 'User ID', id: 3, key: 'id' }
    ];

    const handleSelectedOption = (event) => {
        setSelectedOption(event.target.value);
        setFilterQuery(''); // Clear filter query on selection change
    };

    const filteredItems = currentItems?.filter(item => {
        const userName = item.username.toLowerCase();
        const fullName = item.full_name.toLowerCase();
        const userID = item.id

        switch (selectedOption) {
            case 'Username':
                return userName && userName.includes(filterQuery.toLowerCase());
            case 'Fullname':
                return fullName && fullName.includes(filterQuery.toLowerCase());
            case 'User ID':
                return userID.toString().includes(filterQuery);

            default:
                return true; // No filtering if no valid option is selected
        }
    });

    const exportToExcel = () => {
        // Process each item to structure it for the Excel file
        const processedData = filteredItems.map(item => {

            return {
                'Fullname': item?.full_name,
                'Username': item?.username,
                'User Id': item?.id,
                "Contacts": item?.phone,
                'Completed reservation': item?.completed_reservation,
                'Completed posts': item?.total_posts,
            };
        });

        const ws = XLSX.utils.json_to_sheet(processedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Users");
        XLSX.writeFile(wb, "users.xlsx");
    };


    return (
        <div className="all__container">
            <div
                className="all__arrow"
                onClick={() => {
                    navigate(-1);
                }}
            >
                <img src={ArrowLeft} alt="arrow-left" className='arrow-left' />
            </div>

            <div className="all__content">
                <div className="all__text">
                    <p className="all__recent">Users</p>
                </div>
                <div className='all__fliter'>
                    <select className="top__restaurant select_filter" value={selectedOption} onChange={handleSelectedOption}>
                        <option value="">Filter</option>
                        {options.map((option) => (
                            <option key={option.id} value={option.name}>
                                {option.name}
                            </option>
                        ))}
                        <img src={filter} alt="filter-icon" className="filter__icon" />
                        <img src={CaretDown} alt="caret-down" className="top__caret" />
                    </select>

                    <input
                        type="text"
                        value={filterQuery}
                        onChange={(e) => setFilterQuery(e.target.value)}
                        placeholder="Type to filter..."
                        className="filter-input" // Add appropriate classes as needed
                    />

                    {/* <div className="filter__content">
                        <img src={filter} alt="filter-icon" className="filter__icon" />
                        <p className="filter-text">Filter</p>
                    </div> */}
                    <div className="export__content" onClick={exportToExcel}>
                        <img src={download} alt="download-icon" className="download__icon" />
                        <p className="export-text">Export to Excel</p>
                    </div>
                </div>
            </div>
            <div className="userstable__container">
                <table className='table__container table_users'>
                    <thead>
                        <tr>
                            <th className='table__header__text'>SN</th>
                            <th className='table__header__text'>Full name</th>
                            <th className='table__header__text'>Username</th>
                            <th className='table__header__text'>User ID</th>
                            <th className='table__header__text'>Contacts</th>
                            <th className='table__header__text'>Completed reservations</th>
                            <th className='table__header__text'>Completed posts</th>
                            <th className='table__header__text'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filteredItems?.map((data, index) => (
                                <tr className="reservation__table__rw" key={index + 1}>
                                    <td className="reservation__table__first">{index + 1}</td>
                                    <td className="reservation__table__sec">{data?.full_name}</td>
                                    <td className="reservation__table__sec">{data?.username}</td>
                                    <td className="reservation__table__sec">{data?.id}</td>
                                    <td className="reservation__table__sec">{data?.phone}</td>
                                    <td className="reservation__table__sec">{data?.completed_reservation}</td>
                                    <td className="reservation__table__sec">{data?.total_posts}</td>
                                    <UsersDropdown DataID={data?.id} />
                                </tr>

                            ))
                        }
                    </tbody>
                </table>
                <UsersPagination
                    itemsPerPage={itemsPerPage}
                    totalItems={allUsers?.data?.length}
                    paginate={paginate}
                    className="table_pagination"
                />
            </div>
        </div>
    );
}

export default AllUsers;
