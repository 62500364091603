import React, { useState, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import Header from '../Dashboard/Header';
import Cards from './Cards';
import Analytics from './Analytics';
import RestaurantTable from './RestaurantTable';
import { useSelector, useDispatch } from 'react-redux';
import { getRestaurantInfo, getRestaurantAnalysisInfo, getTopPerformanceInfo, getSalesInfo } from '../../redux/restaurantInfo';


const Restaurant = () => {
    const toast = useToast();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { restaurantData, restaurantAnalysis, performanceData } = useSelector((state) => state.restaurantInfo);

    function showToast(title, message, status) {
        toast({
            title: message,
            status: status,
            duration: 5000,
            isClosable: true,
            position: 'top',
        });
    }


    useEffect(() => {
        const TOKEN = localStorage.getItem('AccessToken');
        if (!TOKEN) {
            showToast("Authentication error", "No access token found", "error");
            setError("Authentication error: No access token found.");
            setIsLoading(false);
            return;
        }

        // Make sure to properly handle the promise returned by the dispatch
        dispatch(getRestaurantInfo(TOKEN)).then(response => {
            if (response?.payload?.data?.status_code == 200) {
                // Data fetched successfully
                setIsLoading(false);
            } else {
                // Handle failure
                setError(response.message || "Failed to fetch dashboard data");
                showToast("Error", response.message || "Failed to fetch dashboard data", "error");
                setIsLoading(false);
            }
        });

        if (!TOKEN) {
            showToast("Authentication error", "No access token found", "error");
            setError("Authentication error: No access token found.");
            setLoading(false);
            return;
        }

        // Make sure to properly handle the promise returned by the dispatch
        dispatch(getRestaurantAnalysisInfo(TOKEN)).then(response => {
            if (response?.payload?.data?.status_code == 200) {
                // Data fetched successfully
                setLoading(false);
            } else {
                // Handle failure
                setError(response.message || "Failed to fetch  restaurant data");
                showToast("Error", response.message || "Failed to fetch  restaurant data", "error");
                setLoading(false);
            }
        });

        if (!TOKEN) {
            showToast("Authentication error", "No access token found", "error");
            setError("Authentication error: No access token found.");
            setLoading(false);
            return;
        }

        // Make sure to properly handle the promise returned by the dispatch
        dispatch(getTopPerformanceInfo(TOKEN)).then(response => {
            if (response?.payload?.data?.status_code == 200) {
                // Data fetched successfully
                setLoading(false);
            } else {
                // Handle failure
                setError(response.message || "Failed to fetch  restaurant data");
                showToast("Error", response.message || "Failed to fetch restaurant data", "error");
                setLoading(false);
            }
        });

    }, [])


    return (
        <div>
            <Header />
            <Cards restaurantAnalysis={restaurantAnalysis} />
            <Analytics performanceData={performanceData} />
            <RestaurantTable restaurantData={restaurantData} />
        </div>
    );
}

export default Restaurant;
