import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useToast
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { storage } from "../../config/firebase";
import { ref, uploadBytes } from 'firebase/storage';
import { getDownloadURL } from 'firebase/storage';
import { v4 } from 'uuid';
import { createAdsInfo, getAllAdsInfo } from "../../redux/advertInfo";

const CreateAddModal = ({ isOpen, onClose }) => {
    const dispatch = useDispatch();
    const toast = useToast();

    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [fromDuration, setFromDuration] = useState('');
    const [toDuration, setToDuration] = useState('');
    const [banner, setBanner] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { adverts } = useSelector((state) => state.advertInfo);

    function showToast(title, message, status) {
        toast({
            title: message,
            status: status,
            duration: 5000,
            isClosable: true,
            position: 'top',
        });
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'title':
                setTitle(value);
                break;
            case 'message':
                setMessage(value);
                break;
            case 'fromDuration':
                setFromDuration(value);
                break;
            case 'toDuration':
                setToDuration(value);
                break;
            default:
                break;
        }
    };

    const TOKEN = localStorage.getItem("AccessToken");

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        // if (!title || !message || !fromDuration || !toDuration || !banner) {
        //     showToast('Error', 'Please fill all fields', 'error');
        //     return;
        // }

        const data = {
            title,
            message,
            from_duration: fromDuration,
            to_duration: toDuration,
            img_url: banner
        };

        try {
            const response = await dispatch(createAdsInfo(data));
            if (response?.payload?.status_code === 200) {
                dispatch(getAllAdsInfo());
                showToast('Success', 'Ad created successfully', 'success');
                onClose();
            } else {
                showToast('Error', 'Failed to create ad', 'error');
                setIsLoading(false)
            }
        } catch (error) {
            console.log('Error creating ad:', error);
            showToast('Error', 'An error occurred', 'error');
        }
    };

    useEffect(() => {
        dispatch(getAllAdsInfo());
    }, [])

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                id="add__modal"
                isCentered
                size="xl"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader className="ads__text">Create Ads</ModalHeader>
                    <ModalCloseButton></ModalCloseButton>
                    <ModalBody>
                        <div className="ads__form">
                            <p className="ads__form__text">Ads Title</p>
                            <input
                                type="text"
                                className="ads__input"
                                placeholder="Enter Ads Title"
                                name="title"
                                onChange={handleChange}
                            ></input>
                        </div>

                        <div className="ads__form">
                            <p className="ads__form__text">Message</p>
                            <input
                                type="text"
                                className="ads__input"
                                placeholder="Enter a message"
                                name="message"
                                onChange={handleChange}
                            ></input>
                        </div>

                        <div className="ads__form">
                            <p className="ads__form__text dur">Duration</p>
                            <div className='duration'>
                                <p className="duration__text">From</p>
                                <input
                                    type="date"
                                    className="ads__input__date"
                                    placeholder="11th Apr 2024"
                                    name="fromDuration"
                                    onChange={handleChange}
                                ></input>
                                <p className="duration__text_i">To</p>
                                <input
                                    type="date"
                                    className="ads__input__date_i"
                                    placeholder="7th May 2024"
                                    name="toDuration"
                                    onChange={handleChange}
                                ></input>
                            </div>
                        </div>

                        <div className="ads__form">
                            <p className="ads__form__text">Upload banner image</p>
                            <input
                                type="file"
                                className="ads__input banner__upload"
                                name="banner"
                                onChange={
                                    (e) => {
                                        setLoading(true)
                                        const file = e.target.files[0];
                                        const imageRef = ref(storage, `restaurant/${file.name + v4()}`)
                                        uploadBytes(imageRef, file).then(() => {
                                            showToast('Image uploaded', 'Image uploaded successfully', 'success');
                                            getDownloadURL(imageRef).then((url) => {
                                                setBanner(url)
                                                setLoading(false)
                                            });
                                        });
                                    }
                                }
                            ></input>
                            {
                                loading ? <p className="banner__loading">Loading...</p> : null
                            }
                        </div>
                        <div className="ads__form">
                            <Button
                                type="submit"
                                className="create__ad__button"
                                isLoading={isLoading}
                                loadingText="Verifying your credentials ..."
                                onClick={handleSubmit}
                            >Done</Button>
                        </div>
                    </ModalBody>

                </ModalContent>
            </Modal>
        </div>
    );
}

export default CreateAddModal;
